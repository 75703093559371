import { Hidden, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import newInterviewsImg from "../../assets/icons/interview.svg";
import newMatchesImg from "../../assets/icons/job.svg";
import BoxLayout from "../BoxLayout";

type InfoBoxProps = {
  type: "upcomingInterviews" | "newMatches";
  value: number;
  centered?: boolean;
};

// To use the InfoBox element, provide the header and desc as props and put the icon as a child element.
const InfoBox: React.FC<InfoBoxProps> = ({ type, value, centered }) => {
  const { classes } = useStyles({ centered });

  return (
    <BoxLayout className={classes.container}>
      {type == "upcomingInterviews" ? (
        <img src={newInterviewsImg} alt="New Interviews Icon" className={classes.icon} />
      ) : (
        <img src={newMatchesImg} alt="New Matches Icon" className={classes.icon} />
      )}
      <div>
        <Typography variant="h5">
          {value}&nbsp;{type == "upcomingInterviews" ? `Upcoming Interview` : `New Match`}
          {value != 1 && (type == "upcomingInterviews" ? "s" : "es")}
        </Typography>
        <Typography>
          <Hidden smDown>
            {type == "upcomingInterviews" ? "Check your schedule" : "Jobs you have matched with"}
          </Hidden>
        </Typography>
      </div>
    </BoxLayout>
  );
};

const useStyles = makeStyles<Partial<InfoBoxProps>>()((theme, props) => ({
  container: {
    height: "100%",
    marginBottom: 0,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4)
    },
    ...(props.centered && {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    })
  },
  icon: {
    width: 56,
    height: 56,
    marginBottom: theme.spacing(0.5)
  }
}));

export default InfoBox;
