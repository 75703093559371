import { Grid } from "@mui/material";
import { ItemOperationsDialog } from "@placehires/react-component-library";
import React, { useState } from "react";
import { useUpdateApplicantResumeMutation } from "../../generated/graphqlHooks";

interface UploadFilesModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const UploadFilesModal: React.FC<UploadFilesModalProps> = ({ open, setOpen }) => {
  const [resumeFile, setResumeFile] = useState(null as File);
  const [updateApplicant, { loading }] = useUpdateApplicantResumeMutation();
  const handleClose = () => setOpen(false);

  const handleUpdate = async () => {
    updateApplicant({
      variables: { resumeFile }
    }).then(handleClose);
  };

  return (
    <ItemOperationsDialog
      open={open}
      onClose={handleClose}
      title="Upload Resume"
      onSave={handleUpdate}
      loadingSave={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <input
            required
            type="file"
            accept="application/pdf"
            onChange={(e) => setResumeFile(e.target.files[0])}
          />
        </Grid>
      </Grid>
    </ItemOperationsDialog>
  );
};

export default UploadFilesModal;
