import { Avatar, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@placehires/react-component-library";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { employmentTypeToReadable, getCompanyAvatarURL } from "../../../constants/general";
import {
  PREVIEW_AVATAR_DIMENSION,
  PREVIEW_AVATAR_DIMENSIONS,
  profileItemDivider
} from "../../../constants/styles";
import { Experience } from "../../../generated/graphqlTypes";
import { useModalState } from "../../../hooks/dialogHooks";
import { sortByStartEndDates, splitArray } from "../../../utils/utils";
import BoxLayout from "../../BoxLayout";
import HeaderWithActions from "../../HeaderWithActions";
import SectionWithHoverEdit from "../../SectionWithHoverEdit";
import ExperiencesModal from "./ExperiencesModal";

const visibleExpNum = 5;

type ExperiencesSectionProps = {
  experiences: Experience[];
  isCurrentUser: boolean;
};

const ExperiencesSection: React.FC<ExperiencesSectionProps> = ({ experiences, isCurrentUser }) => {
  const [open, handleOpen, handleClose] = useModalState();
  const [experienceToUpdate, setExperienceToUpdate] = useState<Experience>();
  const { classes } = useStyles();

  const sortedExperiences = useMemo(() => {
    return sortByStartEndDates(experiences);
  }, [experiences]);
  const [visibleExperiences, expandableExperiences] = splitArray(sortedExperiences, visibleExpNum);

  const experienceNodes = (exps: Experience[]) =>
    exps.map((experience, index) => (
      <div key={index}>
        <SectionWithHoverEdit
          onIconClick={() => {
            setExperienceToUpdate(experience);
            handleOpen();
          }}
          hideEdit={!isCurrentUser}
        >
          <div className={classes.item}>
            <Avatar
              className={classes.avatar}
              variant="rounded"
              src={getCompanyAvatarURL(experience.companyAvatarURL)}
            />
            <div className={classes.itemDetails}>
              <Typography variant="h6">{experience.position}</Typography>
              <Typography color="textSecondary">
                {experience.companyName}
                {experience.employmentType
                  ? ` \u00B7 ${employmentTypeToReadable(experience.employmentType)}`
                  : ""}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                {format(new Date(experience.startDate), "MMM yyyy")} -{" "}
                {experience.endDate ? format(new Date(experience.endDate), "MMM yyyy") : "Present"}
              </Typography>
              <Typography className={classes.itemDescription}>{experience.description}</Typography>
            </div>
          </div>
        </SectionWithHoverEdit>
        {index != exps.length - 1 ? (
          <Divider variant="fullWidth" className={classes.divider} />
        ) : null}
      </div>
    ));

  return (
    <>
      <BoxLayout
        expandableArea={
          !!expandableExperiences.length && (
            <>
              <Divider variant="fullWidth" className={classes.divider} />
              {experienceNodes(expandableExperiences)}
            </>
          )
        }
      >
        <HeaderWithActions
          title="Experiences"
          actions={isCurrentUser && ["add"]}
          actionHandlers={[() => handleOpen()]}
        />
        {experienceNodes(visibleExperiences)}
      </BoxLayout>
      <ExperiencesModal
        open={open}
        onClose={handleClose}
        onExited={() => setExperienceToUpdate(null)}
        defaultExperience={experienceToUpdate}
      />
    </>
  );
};

const avatarRightMargin = 2;

export const useStyles = makeStyles()((theme) => ({
  avatar: {
    ...PREVIEW_AVATAR_DIMENSIONS
  },
  item: {
    display: "flex"
  },
  itemDetails: {
    flex: 1,
    margin: theme.spacing(0, avatarRightMargin)
  },
  itemDescription: {
    whiteSpace: "pre-line"
  },
  divider: {
    ...profileItemDivider(theme),
    marginLeft: `calc(${PREVIEW_AVATAR_DIMENSION}px + ${theme.spacing(avatarRightMargin)})`
  }
}));

export default ExperiencesSection;
