import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { TOOLBAR_HEIGHT } from "../../gatsby-theme-material-ui-top-layout/theme";
import { ApplicationWithJobFieldsFragment } from "../../generated/graphqlTypes";
import BoxLayout from "../BoxLayout";
import ApplicantJobPreview from "../jobs/ApplicantJobPreview";
import JobDetails from "../jobs/JobDetails";
import LoadingComponent from "../LoadingComponent";
import PageTitle from "../PageTitle";

export type ApplicationsWithJobViewProps = {
  title: string;
  subtitle: string;
  emptyDataText: string;
  applications: ApplicationWithJobFieldsFragment[] | undefined;
  loading: boolean;
  getNavigationPath: (application: ApplicationWithJobFieldsFragment) => string;
  showApplicationInfo?: boolean;
};

const ApplicationsWithJobView: React.FC<ApplicationsWithJobViewProps> = ({
  title,
  subtitle,
  applications,
  loading,
  getNavigationPath,
  showApplicationInfo,
  emptyDataText
}) => {
  const [selectedApplicationIndex, setSelectedApplicationIndex] = React.useState(0);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const selectedApplication = (applications || [])[selectedApplicationIndex];

  useEffect(() => {
    if (!selectedApplication && selectedApplicationIndex > 0)
      setSelectedApplicationIndex(selectedApplicationIndex - 1);
  }, [selectedApplication]); // eslint-disable-line

  const maxHeight = smDown
    ? "auto"
    : `calc(100vh - ${TOOLBAR_HEIGHT}px - ${mdDown ? "68" : "76"}px - 4.5rem)`;

  return (
    <>
      <PageTitle title={title} subtitle={subtitle} marginTop />
      {loading ? (
        <LoadingComponent />
      ) : !applications?.length ? (
        <BoxLayout>
          <Typography variant="h6" align="center">
            {emptyDataText}
          </Typography>
        </BoxLayout>
      ) : (
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12}>
            <Box
              sx={{
                display: {
                  sm: "block",
                  overflow: "auto",
                  maxHeight
                }
              }}
            >
              {applications?.map((application, index) => (
                <ApplicantJobPreview
                  key={application.job._id}
                  job={application.job}
                  applicationInfo={showApplicationInfo ? application : undefined}
                  isSelected={index === selectedApplicationIndex}
                  withMb={index !== applications.length - 1}
                  onClick={() => {
                    if (smDown) {
                      navigate(getNavigationPath(application));
                    } else {
                      setSelectedApplicationIndex(index);
                    }
                  }}
                />
              ))}
            </Box>
          </Grid>
          {selectedApplication && !smDown && (
            <Grid item xs={12} sm={8}>
              <BoxLayout
                withoutMargin
                sx={{
                  overflow: "auto",
                  maxHeight
                }}
              >
                <JobDetails
                  job={selectedApplication.job}
                  company={selectedApplication.job.company}
                  application={selectedApplication}
                  withCompanyAvatar
                />
              </BoxLayout>
            </Grid>
          )}
          <Grid />
        </Grid>
      )}
    </>
  );
};

export default ApplicationsWithJobView;
