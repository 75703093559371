import { CalendarPickerView } from "@mui/lab";
import { Grid } from "@mui/material";
import { FormikDatePicker } from "@placehires/react-component-library";
import { useField } from "formik";
import { capitalize, lowerCase } from "lodash";
import React from "react";

export type StartEndDatesProps = {
  views?: CalendarPickerView[]; // Defaults to ["year", "month"],
  startDateProps?: {
    name?: string; // Defaults to "startDate"
    required?: boolean; // Defaults to true
  };
  endDateProps?: {
    name?: string; // Defaults to "endDate"
    label?: string;
    noMaxDate?: boolean;
  };
};

const StartEndDates: React.FC<StartEndDatesProps> = ({
  views = ["year", "month"],
  startDateProps = {},
  endDateProps = {}
}) => {
  const { name: startName, ...startPickerProps } = startDateProps;
  const { name: endName, noMaxDate, ...endPickerProps } = endDateProps;
  const startDateName = startName || "startDate";
  const endDateName = endName || "endDate";
  const [startDateField] = useField(startDateName);
  const [endDateField] = useField(endDateName);

  const capitalizedEndName = capitalize(lowerCase(endDateName));
  const lowerCaseStartName = lowerCase(startDateName);
  const capitalizedStartName = capitalize(lowerCaseStartName);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormikDatePicker
          required
          views={views}
          fullWidth
          name={startDateName}
          maxDate={new Date()}
          maxDateMessage={`${capitalizedStartName} can't be past today's date`}
          {...startPickerProps}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormikDatePicker
          required={!!endDateField.value}
          disabled={!endDateField.value}
          views={views}
          name={endDateName}
          minDate={startDateField.value}
          maxDate={noMaxDate ? undefined : new Date()}
          minDateMessage={`${capitalizedEndName} can't be before ${lowerCaseStartName}`}
          maxDateMessage={`${capitalizedEndName} can't be past today's date`}
          fullWidth
          {...endPickerProps}
        />
      </Grid>
    </>
  );
};

export default StartEndDates;
