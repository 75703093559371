import { Avatar, Button, ButtonProps, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import {
  alertSuccess,
  makeStyles,
  RichTextViewer,
  updateCacheAfterRemove,
  useConfirm
} from "@placehires/react-component-library";
import { Link as GLink } from "gatsby";
import ISO6391 from "iso-639-1";
import React from "react";
import { getCompanyAvatarURL, languageLevelTypeToReadable } from "../../constants/general";
import { PREVIEW_AVATAR_DIMENSION } from "../../constants/styles";
import { CARD_BORDER_RADIUS } from "../../gatsby-theme-material-ui-top-layout/theme";
import {
  useConfirmInterestInJobMutation,
  useRemoveApplicationMutation,
  useShowInterestInJobMutation
} from "../../generated/graphqlHooks";
import {
  ApplicantApplicationDocument,
  ApplicantCreatedApplicationsDocument,
  ApplicantViewableApplicationsDocument,
  Application,
  ApplicationStatus,
  Company,
  Job
} from "../../generated/graphqlTypes";
import { useUser } from "../../hooks/authHooks";
import { useReadNotificationByPath } from "../../hooks/notiticationHooks";
import JobIconDetails from "./JobIconDetails";

type JobDetailsProps = {
  job: Omit<
    Job,
    | "_id"
    | "positionId"
    | "companyId"
    | "recruiterId"
    | "skills"
    | "certifications"
    | "filterDeadline"
    | "maxFilteredApplicants"
    | "maxAppliedApplicants"
  >;
  company: Company;
  application?: Application;
  disableCompanyLink?: boolean;
  withCompanyAvatar?: boolean;
};

const JobDetails: React.FC<JobDetailsProps> = ({
  job,
  company,
  application,
  disableCompanyLink,
  withCompanyAvatar
}) => {
  const { classes } = useStyles();
  const user = useUser();
  const mutationVariables = {
    variables: {
      id: application?._id
    }
  };
  const [showInterestInJob] = useShowInterestInJobMutation({
    ...mutationVariables,
    update: (cache) => {
      updateCacheAfterRemove(cache, ApplicantCreatedApplicationsDocument, application._id);
    }
  });
  const [confirmInterestInJob] = useConfirmInterestInJobMutation(mutationVariables);
  const [removeApplication] = useRemoveApplicationMutation({
    ...mutationVariables,
    refetchQueries: [
      ApplicantCreatedApplicationsDocument,
      ApplicantViewableApplicationsDocument,
      ApplicantApplicationDocument
    ]
  });

  const readNotificationByPath = useReadNotificationByPath(`/app/jobs/${application?.job?._id}`);

  const confirmDiscard = useConfirm({
    title: "Confirm discard",
    text: "Are you sure you want discard this job? You will not be able to apply to this job after discarding it."
  });

  const primaryButtonMap: Record<string, Partial<ButtonProps>> = {
    [ApplicationStatus.CREATED]: {
      children: "I'm Interested",
      onClick: () => {
        readNotificationByPath();
        showInterestInJob().then(() => {
          alertSuccess(
            "You've shown interest in this job. This job will appear under applications if you get matched with it"
          );
        });
      }
    },
    [ApplicationStatus.MATCHED]: {
      children: "Apply",
      onClick: () => {
        readNotificationByPath();
        confirmInterestInJob().then(() => alertSuccess("You've applied to this job."));
      }
    },
    [ApplicationStatus.INTERESTED]: {
      children: "Showed Interest",
      disabled: true
    },
    [ApplicationStatus.UNDER_REVIEW]: {
      children: "Applied",
      disabled: true
    }
  };

  const statusesWithDiscardButton = [ApplicationStatus.CREATED, ApplicationStatus.MATCHED];

  const companyLinkProps = {
    ...(!disableCompanyLink && {
      component: GLink,
      to: `/app/companies/${company._id}`
    })
  };

  return (
    <div>
      <Box
        sx={{
          display: "grid",
          gap: "1.5rem",
          gridTemplateColumns: "auto 1fr",
          justifyItems: "flex-start",
          alignItems: "center"
        }}
      >
        {withCompanyAvatar && (
          <Avatar
            src={getCompanyAvatarURL(company.avatarURL)}
            className={classes.avatar}
            variant="rounded"
            {...companyLinkProps}
          />
        )}
        <div>
          <Typography variant="h5" color="primary" fontWeight={800} mb={1.5}>
            {job.position.name}
          </Typography>
          <JobIconDetails
            avatarURL={!withCompanyAvatar && company.avatarURL}
            companyName={company.name}
            employmentType={job.employmentType}
            location={job.location}
            companyId={!disableCompanyLink && company._id}
          />
          {application &&
            application.applicantId === user.uid &&
            Object.keys(primaryButtonMap).includes(application.status) && (
              <div className={classes.actionArea}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.primaryAction}
                  {...primaryButtonMap[application.status]}
                />
                {statusesWithDiscardButton.includes(application.status) && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      confirmDiscard(() => {
                        readNotificationByPath();
                        removeApplication();
                      });
                    }}
                  >
                    Discard
                  </Button>
                )}
              </div>
            )}
        </div>
      </Box>
      <Divider className={classes.headerDivider} />

      <RichTextViewer value={job.description} />
      {job.yearsOfExperience && (
        <>
          <Typography variant="h6" gutterBottom>
            <strong>Years of experience</strong>
          </Typography>
          <Typography gutterBottom>
            From {job.yearsOfExperience[0]} to {job.yearsOfExperience[1]} years
          </Typography>
        </>
      )}
      {job.yearsOfExperience && (
        <>
          <Typography variant="h6" gutterBottom>
            <strong>Salary (per hour)</strong>
          </Typography>
          <Typography gutterBottom>
            From ${job.salary[0]} to ${job.salary[1]} an hour
          </Typography>
        </>
      )}
      {job.language && (
        <>
          <Typography variant="h6" gutterBottom>
            <strong>Language:</strong>
          </Typography>
          <Typography gutterBottom>{ISO6391.getName(job.language)}</Typography>
          <Typography variant="h6" gutterBottom>
            <strong>Language Proficiency:</strong>
          </Typography>
          <Typography gutterBottom>{languageLevelTypeToReadable(job.languageLevel)}</Typography>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  avatar: {
    borderRadius: CARD_BORDER_RADIUS,
    height: 1.25 * PREVIEW_AVATAR_DIMENSION,
    width: 1.25 * PREVIEW_AVATAR_DIMENSION,
    [theme.breakpoints.up("sm")]: {
      height: 1.5 * PREVIEW_AVATAR_DIMENSION,
      width: 1.5 * PREVIEW_AVATAR_DIMENSION
    }
  },
  actionArea: {
    marginTop: theme.spacing(1),
    display: "flex"
  },
  primaryAction: {
    marginRight: theme.spacing(2)
  },
  headerDivider: {
    margin: theme.spacing(2, 0)
  }
}));

export default JobDetails;
