import { makeStyles } from "@placehires/react-component-library";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import { chunk, inRange, pick } from "lodash";
import React, { useEffect, useState } from "react";
import LoadingComponent from "../components/LoadingComponent";
import QuestionnaireCompletedCover from "../components/questionnaire/QuestionnaireCompletedCover";
import QuestionnaireStartCover from "../components/questionnaire/QuestionnaireStartCover";
import MatrixQuestion, { MatrixAnswer } from "../components/questionnaire/questions/MatrixQuestion";
import { PRIVATE_HOME } from "../constants/general";
import {
  useQuestionnaireResultProgressQuery,
  useQuestionnaireResultQuery,
  useUpdateQuestionnaireAnswersMutation
} from "../generated/graphqlHooks";
import PrivateLayout from "../layout/PrivateLayout";

type QuestionnaireProps = RouteComponentProps;

const Questionnaire: React.FC<QuestionnaireProps> = () => {
  const { classes } = useStyles();
  const [showCover, setShowCover] = useState(true);
  const { data: questionnaireResultData, loading: loadingQuestionnaireResult } =
    useQuestionnaireResultQuery();
  const {
    data: questionnaireProgressData,
    loading: loadingQuestionnaireProgress,
    refetch: refetchQuestionnaireProgress
  } = useQuestionnaireResultProgressQuery();
  const [updateAnswers] = useUpdateQuestionnaireAnswersMutation();

  const [chunkedAnswers, setChunkedAnswers] = useState<MatrixAnswer[][]>([]);
  const [curChunkIndex, setCurChunkIndex] = useState(0);
  const [curChunk, setCurChunk] = useState<MatrixAnswer[]>();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    // Only create chunks the first time questionnaireResultData loads
    if (questionnaireResultData && !curChunk) {
      const ans = questionnaireResultData.questionnaireResult.answers;
      const finished = questionnaireResultData.questionnaireResult.completed;
      setCompleted(finished);
      if (finished || !ans.length) return;
      // Split answers into groups
      const numQuestionsPerMatrix = inRange(ans.length % 10, 1, 3) ? 9 : 10;
      const chunkedAns = chunk(ans, numQuestionsPerMatrix);
      // If last group includes a single question, merge it into second last group
      if (chunkedAns.length >= 2 && chunkedAns[chunkedAns.length - 1].length == 1) {
        const lastChunk = chunkedAns.pop();
        chunkedAns[chunkedAns.length - 1].push(...lastChunk);
      }
      setChunkedAnswers(chunkedAns);
      // Calculate what chunk to start from
      chunkedAns.some((answers, chunkIndex) => {
        const needsAnswer = answers.some((answer) => !answer.optionId);
        if (needsAnswer) {
          setCurChunkIndex(chunkIndex);
          setCurChunk(answers);
        }
        return needsAnswer;
      });
    }
  }, [questionnaireResultData]); // eslint-disable-line

  if (loadingQuestionnaireProgress)
    return (
      <PrivateLayout>
        <LoadingComponent />
      </PrivateLayout>
    );

  const progress = questionnaireProgressData?.questionnaireResultProgress;

  return (
    <PrivateLayout fullHeight className={classes.wrapper}>
      {completed && progress !== 100 ? (
        <LoadingComponent />
      ) : completed && progress === 100 ? (
        <QuestionnaireCompletedCover onContinue={() => navigate(PRIVATE_HOME)} />
      ) : showCover ? (
        <QuestionnaireStartCover progress={progress} onContinue={() => setShowCover(false)} />
      ) : loadingQuestionnaireResult ? (
        <LoadingComponent />
      ) : (
        <MatrixQuestion
          answers={curChunk}
          onChange={(questionId, index, newValue) => {
            const newAnswer = { ...curChunk[index], optionId: newValue };
            const newAnswers = [...curChunk];
            newAnswers[index] = newAnswer;
            setCurChunk(newAnswers);
          }}
          onNext={() => {
            // Save
            updateAnswers({
              variables: {
                id: questionnaireResultData.questionnaireResult._id,
                answers: curChunk.map((answer) => pick(answer, ["questionId", "optionId"]))
              }
            }).then(refetchQuestionnaireProgress);
            // Move to next chunk
            if (curChunkIndex == chunkedAnswers.length - 1) {
              setCompleted(true);
            } else {
              setCurChunk(chunkedAnswers[curChunkIndex + 1]);
              setCurChunkIndex(curChunkIndex + 1);
            }
          }}
        />
      )}
    </PrivateLayout>
  );
};

const useStyles = makeStyles()(() => ({
  wrapper: {
    justifyContent: "center",
    alignItems: "center"
  }
}));

export default Questionnaire;
