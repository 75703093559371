import { Grid } from "@mui/material";
import { pick } from "lodash";
import React from "react";
import { useCompanyQuery } from "../../../generated/graphqlHooks";
import LoadingComponent from "../../LoadingComponent";
import CompanyIntro from "./CompanyIntro";
import CompanyOverview from "./CompanyOverview";

export type CompanyProfileProps = {
  cid: string;
  canModify?: boolean;
  gridXsSizes?: [number, number];
};

const CompanyProfile: React.FC<CompanyProfileProps> = ({
  cid,
  canModify,
  gridXsSizes = [4, 8]
}) => {
  const { data, loading, error } = useCompanyQuery({
    variables: { id: cid }
  });

  if (loading)
    return (
      <Grid item xs={12}>
        <LoadingComponent />
      </Grid>
    );
  if (error) return <h1>Oops, something went wrong</h1>;
  if (!data.company) return <h1>Company not found</h1>;
  const overviewInfo = pick(data.company, [
    "_id",
    "overview",
    "website",
    "industryName",
    "companySize",
    "headquarters",
    "industryId"
  ]);

  return (
    <>
      <Grid item xs={12} sm={gridXsSizes[0]}>
        <CompanyIntro companyInfo={data.company} canModify={canModify} />
      </Grid>
      <Grid item xs={12} sm={gridXsSizes[1]}>
        <CompanyOverview overviewInfo={overviewInfo} canModify={canModify} />
      </Grid>
    </>
  );
};

export default CompanyProfile;
