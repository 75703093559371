import TextField from "@mui/material/TextField";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";

interface TimeRangePickerProps {
  selectedTimes: [string, string];
  setSelectedTimes: (selectedEvent: [string, string]) => void;
}

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({ selectedTimes, setSelectedTimes }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.timeRangePicker}>
      <TextField
        id="time"
        label="Start time"
        type="time"
        defaultValue={selectedTimes[0]}
        variant="outlined"
        onChange={(event) => {
          setSelectedTimes([event.target.value, selectedTimes[1]]);
        }}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        id="time"
        label="End time"
        type="time"
        defaultValue={selectedTimes[1]}
        variant="outlined"
        onChange={(event) => {
          setSelectedTimes([selectedTimes[0], event.target.value]);
        }}
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
      />
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  timeRangePicker: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: 270,
    margin: "0.5rem"
  },
  textField: {
    width: 130
  }
}));

export default TimeRangePicker;
