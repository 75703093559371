import { StaticDatePicker } from "@mui/lab";
import { Button, Grid, Typography } from "@mui/material";
import { alertSuccess, makeStyles } from "@placehires/react-component-library";
import { format } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import {
  useInterviewsForJobQuery,
  useJobWithInterviewQuery,
  useSelectInterviewMutation
} from "../../generated/graphqlHooks";
import BoxLayout from "../BoxLayout";
import PageTitle from "../PageTitle";

const displayDateFormat = "EEEE, MMMM do";

type Interview = {
  __typename?: string;
  _id: string;
  applicantId?: string;
  start?: string | Date;
  end?: string | Date;
  jobId: string;
};

const convertDateTimeToCurrentLocale = (interviews: Interview[]): Interview[] => {
  for (let i = 0; i < interviews.length; i++) {
    interviews[i].start = format(new Date(interviews[i].start), "yyyy-M-d HH:mm");
    interviews[i].end = format(new Date(interviews[i].end), "yyyy-M-d HH:mm");
  }
  return interviews;
};

const ApplicantInterviewScheduler = () => {
  const { classes } = useStyles();

  // TODO: get jobId from previous job page or wherever this page is supposed to be called from
  const jobId = "61479aee88c7f707848d05fc";

  // Queries
  const { data: jobData } = useJobWithInterviewQuery({ variables: { id: jobId } });
  const { data: interviewsData } = useInterviewsForJobQuery({ variables: { jobId: jobId } });

  // Mutation
  const [selectInterviewSlot] = useSelectInterviewMutation();

  const [interviews, setInterviews] = useState<Interview[]>([]);
  const [bookedInterview, setBookedInterview] = useState<Interview>(null);

  // Interview Details Fields
  const [minimumNotice, setMinimumNotice] = useState(1);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");

  const [selectedTimeId, setSelectedTimeId] = useState("");
  const interviewer = "David Nicolson";
  const restaurant = "Bar Burrito";

  useEffect(() => {
    if (jobData) {
      setMinimumNotice(jobData.job.interviewDetails.minimumNotice);
      setDescription(jobData.job.interviewDetails.description);
      setLocation(jobData.job.interviewDetails.location);
    }
    if (interviewsData) {
      let relevantInterviews: Interview[] = interviewsData.interviewsForJob;
      relevantInterviews = convertDateTimeToCurrentLocale(
        relevantInterviews.map((interview) => ({ ...interview }))
      );
      setInterviews(relevantInterviews);
      relevantInterviews.forEach((interview) => {
        if (interview.applicantId) setBookedInterview(interview);
      });
    }
  }, [jobData, interviewsData]);

  const [startDate, setStartDate] = useState(new Date());

  // TODO: display some kind of success feedback to user.
  const handleConfirm = async () => {
    await selectInterviewSlot({ variables: { id: selectedTimeId } });
    alertSuccess("Interview Selected");
    // Maybe should just route to a different page as well
  };

  const displayTimeSlotsOfSelectedDate = () => {
    return (
      <>
        {interviews.map((interview) => {
          if (format(startDate, "MMM Do yyyy") == format(new Date(interview.start), "MMM Do yyyy"))
            return (
              <div className={classes.slot} key={interview._id}>
                <div className={classes.time} onClick={() => setSelectedTimeId(interview._id)}>
                  {format(new Date(interview.start), "HH:mm").toString()} -{" "}
                  {format(new Date(interview.end), "HH:mm").toString()}
                </div>
                <Button
                  className={
                    interview._id == selectedTimeId ? classes.saveButton : classes.hiddenButton
                  }
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleConfirm}
                >
                  Confirm
                </Button>
              </div>
            );
        })}
      </>
    );
  };

  return (
    <>
      <PageTitle title="Book Interview" subtitle="Select your best time slot" />
      <BoxLayout>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={4}>
            <Typography variant="h6">Interview with {interviewer}</Typography>
            <Typography variant="subtitle1">From {restaurant}</Typography>
            <br />
            <Typography variant="body1">
              <b>Location:</b> {location}
            </Typography>
            <Typography variant="body1">
              <b>Minimum notice:</b> {minimumNotice} day(s)
            </Typography>
            <Typography variant="body1">
              <b>Description:</b> {description}
            </Typography>
          </Grid>

          {!bookedInterview ? (
            <Grid item xs={8} className={classes.hasLeftBorder}>
              <Typography variant="h6">Select a Date & Time</Typography>
              <Typography variant="subtitle1">Pick an available time slot</Typography>
              <div className={classes.dateTimeSelectArea}>
                <StaticDatePicker
                  minDate={new Date()}
                  value={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  renderInput={null}
                  displayStaticWrapperAs="desktop"
                  views={["day"]}
                />
                <div>
                  <Typography variant="h6" className={classes.selectedDate}>
                    {format(startDate, displayDateFormat)}
                  </Typography>
                  {displayTimeSlotsOfSelectedDate()}
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item xs={8} className={classes.hasLeftBorder}>
              <Typography variant="h6">
                You have already booked an interview for this position.
              </Typography>
              <Typography variant="subtitle1">Here is your interview time</Typography>
              <br />
              <Typography variant="body1">
                <b>Booked date:</b> {format(new Date(bookedInterview.start), displayDateFormat)}
              </Typography>
              <Typography variant="body1">
                <b>Start time:</b> {format(new Date(bookedInterview.start), "h:mm a")}
              </Typography>
              <Typography variant="body1">
                <b>End time:</b> {format(new Date(bookedInterview.end), "h:mm a")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </BoxLayout>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  hasLeftBorder: {
    borderLeft: "1px solid #33333333"
  },
  dateTimeSelectArea: {
    display: "flex",
    flexDirection: "row"
  },
  slot: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10
  },
  time: {
    flex: "1",
    border: "2px solid rgba(50,50,255,0.5)",
    padding: 5,
    borderRadius: 10,
    marginRight: 5,
    backgroundColor: "rgba(50,50,255,0.2)",
    color: "rgba(0,0,255,0.9)",
    textAlign: "center"
  },
  selectedDate: {
    marginTop: theme.spacing(2)
  },
  saveButton: {
    borderRadius: 10
  },
  hiddenButton: {
    display: "none"
  }
}));

export default ApplicantInterviewScheduler;
