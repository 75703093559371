import {
  FormikAutocomplete,
  FormikDialog,
  removeOptions
} from "@placehires/react-component-library";
import { ExecutionResult } from "graphql";
import React, { useMemo } from "react";
import { useSkillsQuery } from "../../../generated/graphqlHooks";

type SkillsAddModalProps = {
  existingSkills: string[];
  open: boolean;
  onClose: () => void;
  onSave: (skillsIds: string[]) => Promise<ExecutionResult<any>>;
};

const SkillsAddModal: React.FC<SkillsAddModalProps> = ({
  existingSkills,
  open,
  onClose,
  onSave
}) => {
  const { data: skillsData } = useSkillsQuery();

  const skillsOptions = useMemo(
    () => removeOptions(existingSkills, skillsData?.skills, "_id"),
    [existingSkills, skillsData]
  );

  return (
    <FormikDialog
      open={open}
      onClose={onClose}
      title="Add Skills"
      formikConfig={{
        initialValues: {
          skills: []
        },
        onSaveSubmit: async ({ skills }) => {
          await onSave([...existingSkills, ...skills.map((skill) => skill._id)]);
        }
      }}
    >
      <FormikAutocomplete
        name="skills"
        options={skillsOptions}
        getOptionLabel={(skill) => skill.name}
        multiple
        filterSelectedOptions
      />
    </FormikDialog>
  );
};

export default SkillsAddModal;
