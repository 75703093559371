import { Divider, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@placehires/react-component-library";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { profileItemDivider } from "../../../constants/styles";
import { ApplicantCertification } from "../../../generated/graphqlTypes";
import { useModalState } from "../../../hooks/dialogHooks";
import { sortByStartEndDates, splitArray } from "../../../utils/utils";
import BoxLayout from "../../BoxLayout";
import HeaderWithActions from "../../HeaderWithActions";
import SectionWithHoverEdit from "../../SectionWithHoverEdit";
import CertificationsModal from "./CertificationsModal";

const visibleCertNum = 4;

type CertificationSectionProps = {
  certifications: ApplicantCertification[];
  isCurrentUser: boolean;
};

const CertificationSection: React.FC<CertificationSectionProps> = ({
  certifications,
  isCurrentUser
}) => {
  const { classes } = useStyles();
  const [open, handleOpen, handleClose] = useModalState();
  const [certificationToUpdate, setCertificationToUpdate] = useState<ApplicantCertification>();

  const sortedCertifications = useMemo(() => {
    return sortByStartEndDates(certifications, {
      startKey: "issueDate",
      endKey: "expirationDate"
    });
  }, [certifications]);

  const [visibleCertifications, expandableCertifications] = splitArray(
    sortedCertifications,
    visibleCertNum
  );

  const certificationNodes = (certs: ApplicantCertification[]) =>
    certs.map((certification, index) => (
      <div key={index}>
        <SectionWithHoverEdit
          key={index}
          onIconClick={() => {
            setCertificationToUpdate(certification);
            handleOpen();
          }}
          hideEdit={!isCurrentUser}
        >
          <div className={classes.item}>
            <div className={classes.itemDetails}>
              <Typography variant="h6">{certification.name}</Typography>
              <Typography color="textSecondary">{certification.issuingOrganization}</Typography>
              {certification.issueDate && (
                <Typography color="textSecondary">
                  Issued {format(new Date(certification.issueDate), "MMM yyyy")} &middot;{" "}
                  {certification.expirationDate
                    ? `Expires ${format(new Date(certification.expirationDate), "MMM yyyy")}`
                    : "No Expiration Date"}
                </Typography>
              )}
              {certification.credentialID && (
                <Typography color="textSecondary">
                  Credential ID: {certification.credentialID}
                </Typography>
              )}
              {certification.credentialURL && (
                <Link
                  color="textSecondary"
                  target="_blank"
                  href={certification.credentialURL}
                  className={classes.url}
                >
                  See Credential
                </Link>
              )}
            </div>
          </div>
        </SectionWithHoverEdit>
        {index != certs.length - 1 ? (
          <Divider variant="fullWidth" className={classes.divider} />
        ) : null}
      </div>
    ));

  return (
    <>
      <BoxLayout
        expandableArea={
          !!expandableCertifications.length && (
            <>
              <Divider
                variant="fullWidth"
                className={`${classes.divider} ${classes.showMoreDivider}`}
              />
              {certificationNodes(expandableCertifications)}
            </>
          )
        }
      >
        <HeaderWithActions
          title="Certifications"
          actions={isCurrentUser && ["add"]}
          actionHandlers={[() => handleOpen()]}
        />
        {certificationNodes(visibleCertifications)}
      </BoxLayout>
      <CertificationsModal
        open={open}
        onClose={handleClose}
        existingCertificationsIds={certifications.map((cert) => cert._id)}
        defaultCertification={certificationToUpdate}
        onExited={() => setCertificationToUpdate(null)}
      />
    </>
  );
};

const itemMarginRight = 2;

export const useStyles = makeStyles()((theme) => ({
  item: {
    display: "flex"
  },
  itemDetails: {
    flex: 1,
    marginRight: theme.spacing(itemMarginRight)
  },
  url: {
    display: "block",
    marginTop: theme.spacing(1),
    fontWeight: 500
  },
  divider: {
    ...profileItemDivider(theme)
  },
  showMoreDivider: {
    marginRight: `calc(48px + ${theme.spacing(itemMarginRight)})`
  }
}));

export default CertificationSection;
