import React from "react";

export type GlobalState = Record<string, unknown>; // Define type if global state needed

export type GlobalContextType = {
  state: GlobalState;
  updateState: (newState: Partial<GlobalState>) => Promise<GlobalState>;
};

export const initialGlobalState = {};

const GlobalContext: React.Context<GlobalContextType> = React.createContext(null);

export default GlobalContext;
