import { RouteComponentProps } from "@reach/router";
import { fromUnixTime } from "date-fns";
import { navigate } from "gatsby";
import { trimStart } from "lodash";
import React, { useEffect, useState } from "react";
import AuthUserContext, { AuthUser, AuthUserClaims } from "../../../contexts/AuthUserContext";
import { setUserErrorContext } from "../../../services/errorService";
import { auth } from "../../../services/firebaseService";

const withAuthenticationProvider = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P) => {
    const [authUser, setAuthUser] = useState<AuthUser>(null);

    useEffect(() => {
      return auth.onIdTokenChanged((user) => {
        setAuthUser((prev) => {
          if (user) {
            // Refresh token first time page is loaded to keep up-to-date tokens
            user.getIdTokenResult(!prev).then(({ claims }) => {
              const { email, uid, emailVerified, displayName, photoURL } = user;
              const userInfo = {
                email,
                uid,
                emailVerified,
                displayName,
                photoURL,
                ...(claims as AuthUserClaims),
                currentPeriodEnd: claims.currentPeriodEnd && fromUnixTime(claims.currentPeriodEnd)
              };
              setUserErrorContext(userInfo);
              setAuthUser(userInfo);
            });
          } else {
            setUserErrorContext(null);
            if (prev) navigate("/login", { replace: true });
            else {
              // Redirect to login with current pathname and query param, so we can redirect back
              // here on login
              const curUrl = new URL(window.location.href);
              const newParams = new URLSearchParams();
              newParams.append(
                "redirect",
                "/" + trimStart(`${curUrl.pathname}?${curUrl.searchParams.toString()}`, "/")
              );
              navigate(`/login?${newParams.toString()}`, {
                replace: true
              });
            }
          }
          return prev;
        });
      });
    }, []);

    return (
      <AuthUserContext.Provider value={authUser}>
        <WrappedComponent {...props} />
      </AuthUserContext.Provider>
    );
  };
};

export default withAuthenticationProvider;
