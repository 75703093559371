import { Box, Grid } from "@mui/material";
import {
  DialogTitleWithClose,
  findSelectedOption,
  FormikDialog,
  FormikTextField
} from "@placehires/react-component-library";
import { pick } from "lodash";
import React, { useState } from "react";
import { APPLICANT_ROLE } from "../../constants/general";
import {
  useIndustriesQuery,
  useUpdateApplicantIntroMutation,
  useUpdateRecruiterMutation
} from "../../generated/graphqlHooks";
import { Applicant, Recruiter } from "../../generated/graphqlTypes";
import withAuthentication, { AuthInjectedProps } from "../../hocs/withAuthentication";
import { updateProfileImage } from "../../services/authService";
import IndustryAutocomplete from "../input/IndustryAutocomplete";
import AvatarSelect from "./AvatarSelect";

interface UserIntroModalProps extends AuthInjectedProps {
  defaultValues: Applicant | Recruiter;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const UserIntroModal: React.FC<UserIntroModalProps> = ({ defaultValues, open, setOpen, user }) => {
  const [avatar, setAvatar] = useState<File>(null);
  const [updateRecruiter] = useUpdateRecruiterMutation();
  const [updateApplicant] = useUpdateApplicantIntroMutation();
  const { data: industriesData } = useIndustriesQuery();
  const isApplicant = user.role == APPLICANT_ROLE;

  const handleClose = () => setOpen(false);

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      formikConfig={{
        initialValues: {
          ...pick(defaultValues, ["firstName", "lastName", "headline"]),
          industry: findSelectedOption(defaultValues.industryId, industriesData?.industries, "_id")
        },
        onSaveSubmit: async ({ firstName, lastName, headline, industry }) => {
          if (avatar) await updateProfileImage(avatar);
          const variables = {
            applicantRecruiterIntroInput: {
              firstName,
              lastName,
              headline: (headline || "").replace(/\r?\n|\r/g, " "),
              industryId: industry._id
            }
          };
          if (isApplicant) await updateApplicant({ variables });
          else await updateRecruiter({ variables });
        }
      }}
      title={
        <>
          <DialogTitleWithClose onClose={handleClose}>Edit Intro</DialogTitleWithClose>
          <Box mb={2}>
            <AvatarSelect avatarURL={defaultValues.avatarURL} setAvatar={setAvatar} />
          </Box>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormikTextField name="firstName" required fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField required fullWidth name="lastName" />
        </Grid>
        <Grid item xs={12}>
          <IndustryAutocomplete required />
        </Grid>
      </Grid>
    </FormikDialog>
  );
};

export default withAuthentication(UserIntroModal);
