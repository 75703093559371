import { Button, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import questionnaireEnd from "../../assets/questionnaireEnd.png";
import BoxLayout from "../BoxLayout";

type QuestionnaireCompletedCoverProps = {
  onContinue: () => void;
};

const QuestionnaireCompletedCover: React.FC<QuestionnaireCompletedCoverProps> = ({
  onContinue
}) => {
  const { classes } = useStyles();

  return (
    <BoxLayout className={classes.root}>
      <Typography variant="h3" color="primary" align="center">
        You've reached the end!
      </Typography>
      <Typography variant="subtitle1" align="center">
        Your answers will be used to provide you with better matches
      </Typography>
      <div className={classes.centerWrapper}>
        <img src={questionnaireEnd} alt="Completed Image" height={164} />
      </div>
      <div className={classes.centerWrapper}>
        <Button color="primary" variant="contained" size="large" onClick={onContinue}>
          Finish Questionnaire
        </Button>
      </div>
    </BoxLayout>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(-2),
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 8)
    }
  },
  centerWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4)
  }
}));

export default QuestionnaireCompletedCover;
