import { Event } from "@mui/icons-material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import { Calendar, DateLocalizer, SlotInfo, Views } from "react-big-calendar";

interface SchedulerProps {
  localizer: DateLocalizer;
  events: Event[];
  setEvents: (selectedEvent: Event[]) => any;
  startHour: string;
  endHour: string;
}

export type Event = {
  start: string | Date;
  end: string | Date;
};

const Scheduler: React.FC<SchedulerProps> = ({
  localizer,
  events,
  setEvents,
  startHour,
  endHour
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Calendar
        className={classes.scheduleCalendar}
        min={new Date(0, 0, 0, parseInt(startHour.slice(0, 2)), parseInt(startHour.slice(3, 5)), 0)}
        max={new Date(0, 0, 0, parseInt(endHour.slice(0, 2)), parseInt(endHour.slice(3, 5)), 0)}
        selectable
        localizer={localizer}
        events={events}
        views={["work_week", "week"]}
        defaultView={Views.WEEK}
        step={15}
        timeslots={4}
        defaultDate={new Date()}
        onSelectEvent={(selectedEvent: Event) =>
          setEvents(
            events.filter(
              (event) => event.start != selectedEvent.start && event.end != selectedEvent.end
            )
          )
        }
        onSelectSlot={(slotInfo: SlotInfo) => {
          const { start } = slotInfo;
          const { end } = slotInfo;
          setEvents([...events, { start, end }]);
        }}
      />
    </>
  );
};

const useStyles = makeStyles()(() => ({
  scheduleCalendar: {
    height: "45vh !important",
    fontSize: "16px",
    fontFamily: "roboto"
  }
}));

export default Scheduler;
