import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import React from "react";
import LoadingComponent from "../components/LoadingComponent";
import NotFoundComponent from "../components/NotFoundComponent";
import ApplicantProfile from "../components/profile/applicant/ApplicantProfile";
import RecruiterProfile from "../components/profile/recruiter/RecruiterProfile";
import {
  useApplicantQuery,
  useQuestionnaireResultProgressQuery,
  useRecruiterWithoutCompanyQuery,
  useSkillsQuery
} from "../generated/graphqlHooks";
import withAuthentication, { AuthInjectedProps } from "../hocs/withAuthentication";
import { useQueryParams } from "../hooks/routeHooks";
import PrivateLayout from "../layout/PrivateLayout";

interface ProfileProps extends RouteComponentProps, AuthInjectedProps {
  uid?: string;
}

const Profile: React.FC<ProfileProps> = ({ uid, user }) => {
  const queryParams = useQueryParams();
  const {
    data: applicantData,
    loading: applicantLoading,
    error: applicantError
  } = useApplicantQuery({
    variables: { id: uid },
    skip: !uid
  });
  const { data: questionnaireProgressData, loading: loadingQuestionnaireProgress } =
    useQuestionnaireResultProgressQuery();
  const { data: skillsData, loading: skillsLoading } = useSkillsQuery({
    skip: !uid
  });
  const {
    data: recruiterData,
    loading: recruiterLoading,
    error: recruiterError
  } = useRecruiterWithoutCompanyQuery({
    variables: { id: uid },
    skip: !uid
  });
  const loading =
    applicantLoading || recruiterLoading || skillsLoading || loadingQuestionnaireProgress;
  const error = applicantError || recruiterError;
  const data = applicantData || recruiterData;

  if (!uid) {
    navigate(`/app/${user.uid}${queryParams ? "?" + queryParams : ""}`, { replace: true });
    return <div />;
  }

  return (
    <PrivateLayout disableGuttersMobile>
      {loading ? (
        <LoadingComponent fixed />
      ) : error && !data ? (
        <h1>Oops, something went wrong</h1>
      ) : applicantData?.applicant ? (
        <ApplicantProfile
          user={user}
          applicant={applicantData.applicant}
          questionnaireProgress={questionnaireProgressData?.questionnaireResultProgress}
          allSkills={skillsData?.skills}
        />
      ) : recruiterData?.recruiter ? (
        <RecruiterProfile
          user={user}
          recruiter={recruiterData.recruiter}
          questionnaireProgress={questionnaireProgressData?.questionnaireResultProgress}
        />
      ) : (
        <NotFoundComponent />
      )}
    </PrivateLayout>
  );
};

export default withAuthentication(Profile);
