import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import { LAYOUT_PADDING } from "../../constants/styles";
import {
  useQuestionnaireResultProgressQuery,
  useRecruiterApplicationsByStatusQuery
} from "../../generated/graphqlHooks";
import { ApplicationStatus } from "../../generated/graphqlTypes";
import ApplicationPreview from "../applications/RecruiterApplicationPreview";
import BoxLayout from "../BoxLayout";
import LoadingComponent from "../LoadingComponent";
import DashboardTop from "./DashboardTop";
import InfoBox from "./InfoBox";
import QuestionnaireBox from "./QuestionnaireBox";

const RecruiterDashboard = () => {
  const {
    data: underReviewData,
    loading: underReviewLoading,
    error: underReviewError
  } = useRecruiterApplicationsByStatusQuery({
    variables: { status: ApplicationStatus.UNDER_REVIEW }
  });
  const {
    data: selectedData,
    loading: selectedLoading,
    error: selectedError
  } = useRecruiterApplicationsByStatusQuery({
    variables: { status: ApplicationStatus.SELECTED_FOR_INTERVIEW }
  });
  const { data: questionnaireProgressData, loading: loadingQuestionnaireProgress } =
    useQuestionnaireResultProgressQuery();
  const { classes } = useStyles();

  const loading = underReviewLoading || selectedLoading || loadingQuestionnaireProgress;
  const error = underReviewError || selectedError;

  if (loading) return <LoadingComponent />;
  if (error) return <h1>{error.message}</h1>;

  const numNewMatches = underReviewData?.recruiterApplicationsByStatus.length || 0;

  return (
    <>
      <DashboardTop numNewMatches={numNewMatches} />
      <Grid container spacing={3} className={classes.grid} justifyContent="flex-end">
        {questionnaireProgressData.questionnaireResultProgress !== 100 && (
          <Grid item xs={12}>
            <QuestionnaireBox
              progressValue={questionnaireProgressData.questionnaireResultProgress}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <InfoBox type="newMatches" value={numNewMatches} />
        </Grid>
        <Grid item xs={6}>
          <InfoBox
            type="upcomingInterviews"
            value={selectedData?.recruiterApplicationsByStatus?.length || 0}
          />
        </Grid>
        <Grid item xs={12}>
          <BoxLayout>
            <div>
              <Typography variant="h6">Students You Have Matched With</Typography>
              <Typography variant="body1" color="textSecondary">
                Private to you.
              </Typography>
              {!numNewMatches ? (
                <Typography variant="h5">
                  You don't have any new matches today. Try again tomorrow maybe!
                </Typography>
              ) : (
                underReviewData.recruiterApplicationsByStatus.map((application) => (
                  <ApplicationPreview application={application} key={application._id} />
                ))
              )}
            </div>
          </BoxLayout>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  grid: {
    marginTop: theme.spacing(LAYOUT_PADDING)
  }
}));

export default RecruiterDashboard;
