import { EditOutlined } from "@mui/icons-material";
import { Avatar, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import { Company } from "../../../generated/graphqlTypes";
import { useModalState } from "../../../hooks/dialogHooks";
import BoxLayout, { BOX_LAYOUT_BORDER_RADIUS } from "../../BoxLayout";
import CompanyIntroModal from "./CompanyIntroModal";

type CompanyTopInfoProps = {
  companyInfo: Company;
  canModify?: boolean;
};

const CompanyIntro: React.FC<CompanyTopInfoProps> = ({ companyInfo, canModify }) => {
  const { name, avatarURL } = companyInfo;
  const { classes } = useStyles();
  const [introOpen, openIntro, closeIntro] = useModalState();

  return (
    <BoxLayout className={classes.paper} withoutMargin>
      <div className={classes.contentWrapper}>
        <IconButton
          sx={{
            position: "absolute",
            right: "-1rem",
            top: "-1rem",
            display: canModify ? "block" : "none"
          }}
          onClick={openIntro}
        >
          <EditOutlined sx={{ color: "primary.contrastText" }} />
        </IconButton>
        <Avatar className={classes.avatar} src={avatarURL} variant="rounded" />

        <div className={classes.infoWrapper}>
          <Typography variant="h4">{name}</Typography>
        </div>
      </div>
      <CompanyIntroModal open={introOpen} handleClose={closeIntro} defaultValues={companyInfo} />
    </BoxLayout>
  );
};

const useStyles = makeStyles()((theme) => ({
  paper: {
    position: "relative",
    zIndex: 1,
    ":after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: 115,
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: `${BOX_LAYOUT_BORDER_RADIUS}px ${BOX_LAYOUT_BORDER_RADIUS}px 0 0`,
      zIndex: -1
    }
  },
  contentWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%"
  },
  avatar: {
    width: 150,
    height: 150
  },
  infoWrapper: {
    width: "100%",
    marginTop: theme.spacing(2)
  }
}));

export default CompanyIntro;
