import { Divider, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@placehires/react-component-library";
import React, { useState } from "react";
import { COMPANY_SIZE_OPTIONS } from "../../../constants/general";
import { Company } from "../../../generated/graphqlTypes";
import { validatedLink } from "../../../utils/validate";
import BoxLayout from "../../BoxLayout";
import HeaderWithActions from "../../HeaderWithActions";
import CompanyOverviewModal from "./CompanyOverviewModal";

type CompanyOverviewProps = {
  overviewInfo: Company;
  canModify?: boolean;
};

const CompanyOverview: React.FC<CompanyOverviewProps> = ({ overviewInfo, canModify }) => {
  const { classes } = useStyles();
  const { overview, headquarters, industryName, website, companySize } = overviewInfo;
  const [openOverview, setOpenOverview] = useState(false);
  const companySizeValue = COMPANY_SIZE_OPTIONS.find((entry) => entry.value === companySize).label;

  return (
    <>
      <BoxLayout>
        <HeaderWithActions
          title="Overview"
          actions={canModify && ["edit"]}
          actionHandlers={[() => setOpenOverview(true)]}
        />
        <Typography className={classes.overview}>{overview}</Typography>
        <Divider variant="fullWidth" className={classes.divider} />
        <SmallSection title="Website" body={website} link />
        <SmallSection title="Industry" body={industryName} />
        <SmallSection title="Company Size" body={companySizeValue} />
        <SmallSection title="Headquarters" body={headquarters} />
      </BoxLayout>
      <CompanyOverviewModal
        defaultValues={overviewInfo}
        open={openOverview}
        setOpen={setOpenOverview}
      />
    </>
  );
};

const SmallSection: React.FC<{
  title: string;
  body: string;
  link?: boolean;
}> = ({ title, body, link = false }) => {
  const { classes } = useStyles();

  return (
    body && (
      <div className={classes.section}>
        <Typography variant="h6">{title}</Typography>

        <Typography color="textSecondary">
          {link ? <Link href={validatedLink(body)}>{body}</Link> : body}
        </Typography>
      </div>
    )
  );
};

const useStyles = makeStyles()((theme) => ({
  overview: {
    whiteSpace: "pre-line"
  },
  section: {
    paddingTop: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  }
}));

export default CompanyOverview;
