import { Button, Grid, Step, StepContent, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import {
  ExtendedButton,
  findSelectedOptions,
  FormikAutocomplete,
  FormikDatePicker,
  FormikRichTextField,
  FormikSlider,
  FormikTextField,
  makeStyles,
  updateCacheAfterAdd
} from "@placehires/react-component-library";
import { addDays } from "date-fns";
import { convertToRaw, EditorState } from "draft-js";
import { Form, Formik, FormikProps } from "formik";
import { navigate } from "gatsby";
import ISO6391 from "iso-639-1";
import { isEqual, pick } from "lodash";
import React, { useRef } from "react";
import { EMPLOYMENT_TYPE_OPTIONS, LANGUAGE_LEVEL_OPTIONS } from "../../constants/general";
import {
  useCertificationsQuery,
  useCreateJobMutation,
  useCurrentRecruiterCompanyQuery,
  usePositionsQuery,
  useSkillsQuery
} from "../../generated/graphqlHooks";
import {
  Certification,
  EmploymentType,
  JobTemplateByPositionDocument,
  LanguageProficiencyType,
  Position,
  RecruiterJobsDocument,
  Skill
} from "../../generated/graphqlTypes";
import { useCustomLazyQuery } from "../../hooks/apolloHooks";
import JobDetails from "./JobDetails";

const steps = ["Basic Information", "Job Details", "Preview"];
const minDaysTillFilterDeadline = process.env.GATSBY_ACTIVE_ENV === "production" ? 14 : 4;
const salaryMin = 10;
const salaryMax = 30;
const yoeMin = 0;
const yoeMax = 10;
const minMaxAppliedApplicants = 15;
const minMaxFilteredApplicants = 5;

const emptyJobDetails = {
  description: "",
  skills: [] as Skill[],
  certifications: [] as Certification[]
};

// Made into function to be able to mock (new Date()) value during tests
const emptyForm = () => ({
  position: null as Position,
  employmentType: null as EmploymentType,
  location: "",
  language: "",
  languageLevel: null as LanguageProficiencyType,
  salary: [salaryMin, salaryMax],
  yearsOfExperience: [yoeMin, yoeMax],
  filterDeadline: addDays(new Date(), minDaysTillFilterDeadline),
  maxAppliedApplicants: minMaxAppliedApplicants,
  maxFilteredApplicants: minMaxFilteredApplicants,
  ...emptyJobDetails
});

const salarySliderMarks = [
  {
    value: 10,
    label: "$10"
  },
  {
    value: 30,
    label: "$30"
  }
];

const yearsOfExpSliderMarks = [
  {
    value: 0,
    label: "0"
  },
  {
    value: 10,
    label: "10"
  }
];

type JobForm = ReturnType<typeof emptyForm>;

const CreateJobForm = () => {
  const { classes } = useStyles();
  const [createJob] = useCreateJobMutation();
  const isVertical = useMediaQuery("(min-width:1000px)");

  const { data: positionData, loading: positionLoading } = usePositionsQuery();
  const { data: skillData, loading: skillLoading } = useSkillsQuery();
  const { data: certificationData, loading: certificationLoading } = useCertificationsQuery();
  const { data: companyData } = useCurrentRecruiterCompanyQuery();
  const skillOptions = skillData?.skills;
  const certificationOptions = certificationData?.certifications;
  const positionOptions = positionData?.positions;
  const recruiterCompany = companyData?.currentRecruiterCompany;

  const getJobTemplate = useCustomLazyQuery(JobTemplateByPositionDocument);
  const [activeStep, setActiveStep] = React.useState(0);
  const formRef = useRef<HTMLFormElement>();
  const descriptionEditorStateRef = useRef<EditorState>();

  const updateDescription = (formikProps: FormikProps<JobForm>) => {
    formikProps.setValues((values) => ({
      ...values,
      description: descriptionEditorStateRef.current
        ? JSON.stringify(convertToRaw(descriptionEditorStateRef.current.getCurrentContent()))
        : ""
    }));
  };

  const basicInfo = ({ values }: FormikProps<JobForm>) => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormikAutocomplete
              required
              fullWidth
              options={positionOptions || []}
              getOptionLabel={(option) => option.name || ""}
              label="Position"
              name="position"
              loading={positionLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField
              required
              select
              fullWidth
              name="employmentType"
              SelectProps={{
                options: EMPLOYMENT_TYPE_OPTIONS
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField required fullWidth name="location" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikAutocomplete
              fullWidth
              name="language"
              label="Language"
              options={ISO6391.getAllNames()}
              isOptionEqualToValue={(opt, val) => opt === val}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikTextField
              fullWidth
              select
              SelectProps={{
                options: LANGUAGE_LEVEL_OPTIONS
              }}
              required={!!values.language}
              name="languageLevel"
              label="Language Proficiency"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikTextField
              name="maxAppliedApplicants"
              required
              fullWidth
              type="number"
              inputProps={{
                min: minMaxAppliedApplicants
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikTextField
              name="maxFilteredApplicants"
              required
              fullWidth
              type="number"
              inputProps={{
                min: minMaxFilteredApplicants
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikDatePicker
              name="filterDeadline"
              required
              minDate={addDays(new Date(), minDaysTillFilterDeadline)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSlider
              aria-labelledby="Years of Experience"
              valueLabelDisplay="auto"
              step={1}
              min={yoeMin}
              max={yoeMax}
              name="yearsOfExperience"
              marks={yearsOfExpSliderMarks}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSlider
              aria-labelledby="Hourly salary"
              valueLabelDisplay="auto"
              step={1}
              min={salaryMin}
              max={salaryMax}
              name="salary"
              label="Salary (hourly)"
              marks={salarySliderMarks}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const jobFormBody = (formikProps: FormikProps<JobForm>) => {
    return (
      <div>
        <div>
          {getStepContent(formikProps, activeStep)}
          <div className={classes.buttonArea}>
            <Button
              disabled={activeStep === 0}
              onClick={() => handleBack(formikProps)}
              className={classes.backButton}
            >
              Back
            </Button>
            <ExtendedButton
              variant="contained"
              color="primary"
              loading={formikProps.isSubmitting}
              onClick={() => {
                if (activeStep < steps.length - 1) {
                  const nativelyValid = formRef.current.reportValidity();
                  if (nativelyValid) {
                    updateDescription(formikProps);
                    setActiveStep((activeStep) => activeStep + 1);
                  }
                } else {
                  formikProps.submitForm();
                }
              }}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </ExtendedButton>
          </div>
        </div>
      </div>
    );
  };

  const jobDetailsForm = ({ values, setValues }: FormikProps<JobForm>) => {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              disabled={
                !isEqual(emptyJobDetails, pick(values, ["description", "skills", "certifications"]))
              }
              onClick={async () => {
                const jobTemplateData = await getJobTemplate({ id: values.position._id });
                if (jobTemplateData) {
                  const { description, skills, certifications } =
                    jobTemplateData.jobTemplateByPosition;
                  setValues((values) => ({
                    ...values,
                    description,
                    skills: findSelectedOptions(skills, skillOptions, "_id"),
                    certifications: findSelectedOptions(certifications, certificationOptions, "_id")
                  }));
                }
              }}
              className={classes.populateButtonArea}
            >
              Populate from template
            </Button>
          </Grid>

          <Grid item xs={12}>
            <FormikRichTextField
              required
              variant="outlined"
              name="description"
              label="Job Description"
              valueRef={descriptionEditorStateRef}
              minHeight={80}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikAutocomplete
              multiple
              fullWidth
              label="Skills"
              name="skills"
              loading={certificationLoading}
              options={skillOptions || []}
              isOptionEqualToValue={(opt, val) => opt._id === val._id}
              getOptionLabel={(option) => {
                return option.name || "";
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormikAutocomplete
              multiple
              fullWidth
              label="Certifications"
              name="certifications"
              loading={skillLoading}
              options={certificationOptions || []}
              isOptionEqualToValue={(opt, val) => opt._id === val._id}
              getOptionLabel={(option) => option.name || ""}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const getStepContent = (formikProps: FormikProps<JobForm>, stepIndex: number) => {
    const { language } = formikProps.values;

    switch (stepIndex) {
      case 0:
        return basicInfo(formikProps);
      case 1:
        return jobDetailsForm(formikProps);
      case 2:
        return (
          <JobDetails
            job={{
              ...formikProps.values,
              language: language ? ISO6391.getCode(language) : null
            }}
            company={recruiterCompany}
            disableCompanyLink
          />
        );
    }
  };

  const handleBack = (formikProps: FormikProps<JobForm>) => {
    updateDescription(formikProps);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Formik
      initialValues={emptyForm()}
      onSubmit={async (values) => {
        const {
          position,
          language,
          skills = [],
          certifications = [],
          languageLevel,
          ...rest
        } = values;
        const { data: createdJobData } = await createJob({
          variables: {
            jobFields: {
              ...rest,
              positionId: position?._id,
              language: language ? ISO6391.getCode(language) : null,
              languageLevel: languageLevel || null,
              skills: skills.map((skill) => skill._id),
              certifications: certifications.map((certification) => certification._id)
            }
          },
          update: (cache, { data }) => {
            updateCacheAfterAdd(cache, RecruiterJobsDocument, data.createJob, {
              unshift: true
            });
          }
        });
        await navigate(`/app/jobs/${createdJobData.createJob._id}`);
      }}
    >
      {(formikProps) => (
        <Form ref={formRef} className={classes.form}>
          <Stepper
            className={classes.stepper}
            activeStep={activeStep}
            orientation={isVertical ? "horizontal" : "vertical"}
          >
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  {!isVertical ? <StepContent>{jobFormBody(formikProps)}</StepContent> : null}
                </Step>
              );
            })}
          </Stepper>
          {isVertical ? jobFormBody(formikProps) : null}
        </Form>
      )}
    </Formik>
  );
};

const useStyles = makeStyles()((theme) => ({
  form: {
    padding: theme.spacing(1)
  },
  stepper: {
    padding: theme.spacing(1, 0, 4)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  buttonArea: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
  populateButtonArea: {}
}));

export default CreateJobForm;
