import React from "react";
import { useApplicantViewableApplicationsQuery } from "../../generated/graphqlHooks";
import ApplicationsWithJobView from "./ApplicationsWithJobView";

const ApplicantApplications = () => {
  const { data, loading } = useApplicantViewableApplicationsQuery();

  return (
    <ApplicationsWithJobView
      title="Your Applications"
      subtitle="View your applications"
      emptyDataText="This is where your applications would appear"
      applications={data?.applicantViewableApplications}
      loading={loading}
      getNavigationPath={(application) => `/app/jobs/${application.job._id}`}
      showApplicationInfo
    />
  );
};

export default ApplicantApplications;
