import { RouteComponentProps } from "@reach/router";
import React from "react";
import BoxLayout from "../components/BoxLayout";
import CreateJobForm from "../components/jobs/CreateJobForm";
import NotFoundComponent from "../components/NotFoundComponent";
import PageTitle from "../components/PageTitle";
import { RECRUITER_ROLE } from "../constants/general";
import withAuthentication, { AuthInjectedProps } from "../hocs/withAuthentication";
import PrivateLayout from "../layout/PrivateLayout";

// TODO: stripe readd on non-free pricing
const CreateJob: React.FC<RouteComponentProps & AuthInjectedProps> = ({ user }) => {
  // const { accessLevel, expired } = useAccessInfo();

  return (
    <PrivateLayout>
      {user.role !== RECRUITER_ROLE ? (
        <NotFoundComponent />
      ) : (
        // ) : expired ? (
        //   <ExpiredSubscription />
        // ) : !accessLevel ? (
        //   <Pricing title="Select a plan to start creating jobs" />
        <>
          <PageTitle
            title="Create a Job"
            subtitle=" Post a job so others can see it and apply through our matching system"
          />
          <BoxLayout>
            <CreateJobForm />
          </BoxLayout>
        </>
      )}
    </PrivateLayout>
  );
};

export default withAuthentication(CreateJob);
