import { RouteComponentProps } from "@reach/router";
import React, { Component } from "react";
import GlobalContext, { GlobalState, initialGlobalState } from "../../../contexts/GlobalContext";

const withGlobalContextProvider = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  return class WithGlobalContextProvider extends Component<P, GlobalState> {
    constructor(props: P) {
      super(props);
      this.state = initialGlobalState;
    }

    updateState = (newState: Partial<GlobalState>): Promise<GlobalState> => {
      return new Promise((resolve) => {
        this.setState(
          {
            ...this.state,
            ...newState
          },
          () => resolve(this.state)
        );
      });
    };

    render() {
      return (
        <GlobalContext.Provider
          value={{
            updateState: this.updateState,
            state: this.state
          }}
        >
          <WrappedComponent {...this.props} />
        </GlobalContext.Provider>
      );
    }
  };
};

export default withGlobalContextProvider;
