import { Avatar, Grid, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { Link } from "gatsby";
import React, { useState } from "react";
import { APPLICANT_ROLE } from "../../constants/general";
import {
  useApplicantViewableApplicationsQuery,
  useQuestionnaireResultProgressQuery
} from "../../generated/graphqlHooks";
import { ApplicationStatus } from "../../generated/graphqlTypes";
import { useUser } from "../../hooks/authHooks";
import BoxLayout from "../BoxLayout";
import LoadingComponent from "../LoadingComponent";
import InfoBox from "./InfoBox";
import MatchCard from "./MatchCard";
import QuestionnaireBox from "./QuestionnaireBox";

const ApplicantDashboard = () => {
  const { classes } = useStyles();
  const user = useUser();
  const { data, loading, error } = useApplicantViewableApplicationsQuery();
  const { data: questionnaireProgressData, loading: loadingQuestionnaireProgress } =
    useQuestionnaireResultProgressQuery();
  let numNewMatches = 0;
  let numUpcomingInterviews = 0;

  if (data) {
    data.applicantViewableApplications.forEach(({ status }) => {
      if (status == ApplicationStatus.CREATED) {
        numNewMatches++;
      } else if (status == ApplicationStatus.SELECTED_FOR_INTERVIEW) {
        numUpcomingInterviews++;
      }
    });
  }
  const [upcomingInterviews, setUpcomingInterviews] = useState(numUpcomingInterviews);

  if (loading || loadingQuestionnaireProgress) return <LoadingComponent fixed />;
  if (error) return <h1>{error.message}</h1>;

  const addInterview = () => {
    setUpcomingInterviews(upcomingInterviews + 1);
  };

  numNewMatches = 0; // TODO: remove
  const newStats = (centered?: boolean) => (
    <>
      <Grid item xs={6}>
        <InfoBox type="newMatches" value={numNewMatches} centered={centered} />
      </Grid>
      <Grid item xs={6}>
        <InfoBox type="upcomingInterviews" value={numUpcomingInterviews} centered={centered} />
      </Grid>
    </>
  );

  return (
    <Grid container spacing={3}>
      <Grid item sm={4} xs={12}>
        <BoxLayout withoutMargin className={classes.avatarBox}>
          <Avatar
            component={Link}
            className={classes.avatar}
            src={user.photoURL}
            to={`/app/${user.uid}`}
          />
          <Typography variant="h4" mt={2} gutterBottom>
            {user.displayName}
          </Typography>
        </BoxLayout>
      </Grid>
      {questionnaireProgressData.questionnaireResultProgress !== 100 ? (
        <>
          <Grid item xs={12} sm={8}>
            <QuestionnaireBox
              progressValue={questionnaireProgressData.questionnaireResultProgress}
            />
          </Grid>
          {newStats()}
        </>
      ) : (
        <Grid item xs={12} sm={8} container spacing={3}>
          {newStats(true)}
        </Grid>
      )}
      <Grid item xs={12}>
        <BoxLayout className={classes.jobs}>
          <Typography variant="h5" color="primary" gutterBottom>
            Jobs You Have Matched With
          </Typography>
          {!numNewMatches ? (
            <Typography variant="h5">
              You don't have any new matches today. Try again tomorrow maybe!
            </Typography>
          ) : (
            data.applicantViewableApplications.map(
              (application) =>
                application.status == ApplicationStatus.CREATED && (
                  // TODO: update or remove
                  <MatchCard
                    application={application}
                    userRole={APPLICANT_ROLE}
                    key={application._id}
                    callback={addInterview}
                  />
                )
            )
          )}
        </BoxLayout>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => ({
  avatarBox: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 1,
    ":after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: 115,
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      zIndex: -1
    }
  },
  avatar: {
    position: "relative",
    cursor: "pointer",
    width: 120,
    height: 120,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)"
  },
  jobs: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4)
    }
  }
}));

export default ApplicantDashboard;
