import {
  findSelectedOption,
  FormikDialog,
  getInitialValues,
  removeOptions,
  updateCacheAfterAdd,
  updateCacheAfterRemove
} from "@placehires/react-component-library";
import React, { useMemo } from "react";
import * as yup from "yup";
import {
  useAddApplicantCertificationMutation,
  useCertificationsQuery,
  useDeleteApplicantCertificationMutation,
  useUpdateApplicantCertificationMutation
} from "../../../generated/graphqlHooks";
import {
  ApplicantCertification,
  ApplicantDocument,
  Certification
} from "../../../generated/graphqlTypes";
import withAuthentication, { AuthInjectedProps } from "../../../hocs/withAuthentication";
import CertificationsForm from "./CertificationsForm";

interface CertificationModalProps extends AuthInjectedProps {
  open: boolean;
  onClose: () => void;
  onExited: () => void;
  defaultCertification: ApplicantCertification;
  existingCertificationsIds: string[];
}

const CertificationsModal: React.FC<CertificationModalProps> = ({
  open,
  onClose,
  onExited,
  user,
  defaultCertification,
  existingCertificationsIds
}) => {
  const [addCertification] = useAddApplicantCertificationMutation();
  const [updateCertification] = useUpdateApplicantCertificationMutation();
  const [deleteCertification] = useDeleteApplicantCertificationMutation();
  const { data: certificationsData, loading: loadingCertifications } = useCertificationsQuery();
  const certificationsOptions = useMemo(
    () => removeOptions(existingCertificationsIds, certificationsData?.certifications, "_id"),
    [existingCertificationsIds, certificationsData]
  );

  return (
    <FormikDialog
      open={open}
      onExited={onExited}
      onClose={onClose}
      title="Add Certification"
      formikConfig={{
        initialValues: getInitialValues(
          {
            certification: null as Certification,
            issuingOrganization: "",
            issueDate: null,
            expirationDate: null,
            credentialID: "",
            credentialURL: ""
          },
          defaultCertification && {
            ...defaultCertification,
            certification: findSelectedOption(
              defaultCertification._id,
              certificationsData?.certifications,
              "_id"
            )
          }
        ),
        validationSchema: yup.object().shape({
          credentialURL: yup.string().url("Please provide a valid URL").nullable()
        }),
        onSaveSubmit: async (certificationForm) => {
          const { certification, ...certificationFields } = certificationForm;
          if (!defaultCertification) {
            await addCertification({
              variables: {
                _id: certification._id,
                certificationFields
              },
              update: (cache, mutationResult) => {
                updateCacheAfterAdd(
                  cache,
                  ApplicantDocument,
                  mutationResult.data.addApplicantCertification,
                  {
                    queryVariables: { id: user.uid },
                    toUpdateListName: "certifications"
                  }
                );
              }
            });
          } else {
            updateCertification({
              variables: {
                id: defaultCertification._id,
                certificationFields
              }
            });
          }
        },
        onDeleteSubmit:
          defaultCertification &&
          (() => {
            return deleteCertification({
              variables: {
                id: defaultCertification._id
              },
              update: (cache) => {
                updateCacheAfterRemove(cache, ApplicantDocument, defaultCertification._id, {
                  queryVariables: { id: user.uid },
                  toUpdateListName: "certifications"
                });
              }
            });
          })
      }}
    >
      <CertificationsForm
        isEdit={!!defaultCertification}
        certifications={certificationsOptions}
        loadingCertifications={loadingCertifications}
      />
    </FormikDialog>
  );
};

export default withAuthentication(CertificationsModal);
