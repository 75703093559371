import {
  FormikDialog,
  FormikTextField,
  getInitialValues
} from "@placehires/react-component-library";
import React from "react";
import { RECRUITER_ROLE } from "../../constants/general";
import {
  useUpdateApplicantIntroMutation,
  useUpdateRecruiterMutation
} from "../../generated/graphqlHooks";
import { Applicant, Recruiter } from "../../generated/graphqlTypes";
import withAuthentication, { AuthInjectedProps } from "../../hocs/withAuthentication";

interface UserAboutModalProps extends AuthInjectedProps {
  defaultValues: Applicant | Recruiter;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const UserAboutModal: React.FC<UserAboutModalProps> = ({ defaultValues, open, setOpen, user }) => {
  const [updateApplicant] = useUpdateApplicantIntroMutation();
  const [updateRecruiter] = useUpdateRecruiterMutation();

  const handleClose = () => setOpen(false);

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title="Edit About"
      formikConfig={{
        initialValues: getInitialValues({ summary: "" }, defaultValues),
        onSaveSubmit: async ({ summary }) => {
          const variables = {
            applicantRecruiterIntroInput: {
              summary
            }
          };
          if (user.role == RECRUITER_ROLE) await updateRecruiter({ variables });
          else await updateApplicant({ variables });
        }
      }}
    >
      <FormikTextField name="summary" fullWidth multiline rows={4} />
    </FormikDialog>
  );
};

export default withAuthentication(UserAboutModal);
