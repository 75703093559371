import { AddCircle } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import {
  useQuestionnaireResultProgressQuery,
  useRecruiterJobsQuery
} from "../../generated/graphqlHooks";
import { useUser } from "../../hooks/authHooks";
import BoxLayout from "../BoxLayout";
import LoadingComponent from "../LoadingComponent";
import PageTitle from "../PageTitle";
import RecruiterJobPreview from "./RecruiterJobPreview";

const RecruiterJobs = () => {
  const { data, loading } = useRecruiterJobsQuery();
  const { data: progressData, loading: loadingProgress } = useQuestionnaireResultProgressQuery();
  const user = useUser();
  const canAddNewJob =
    user.emailVerified && progressData && progressData.questionnaireResultProgress === 100;

  return (
    <>
      <PageTitle
        title="Your Jobs"
        subtitle="View all your previous and current job postings"
        action={{
          text: "Post Job",
          icon: AddCircle,
          link: "/app/jobs/create",
          disabled: !canAddNewJob,
          disabledTooltipText: user.emailVerified
            ? "Please complete the questionnaire first"
            : "Please verify your email first"
        }}
      />
      {loading || loadingProgress ? (
        <LoadingComponent />
      ) : (
        <BoxLayout>
          {!data?.recruiterJobs.length ? (
            <Typography variant="h6" align="center">
              This is where your jobs would appear
            </Typography>
          ) : (
            data.recruiterJobs.map((job, index) => (
              <RecruiterJobPreview
                job={job}
                key={job._id}
                withDivider={index !== data.recruiterJobs.length - 1}
              />
            ))
          )}
        </BoxLayout>
      )}
    </>
  );
};

export default RecruiterJobs;
