import { RouteComponentProps } from "@reach/router";
import React from "react";
import ApplicantApplications from "../components/applications/ApplicantApplications";
import NotFoundComponent from "../components/NotFoundComponent";
import { APPLICANT_ROLE } from "../constants/general";
import withAuthentication, { AuthInjectedProps } from "../hocs/withAuthentication";
import PrivateLayout from "../layout/PrivateLayout";

const Applications: React.FC<RouteComponentProps & AuthInjectedProps> = ({ user }) => {
  return (
    <PrivateLayout>
      {user.role !== APPLICANT_ROLE ? <NotFoundComponent /> : <ApplicantApplications />}
    </PrivateLayout>
  );
};

export default withAuthentication(Applications);
