import { Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { findLabel, makeStyles } from "@placehires/react-component-library";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { EDUCATION_DEGREE_OPTIONS } from "../../../constants/general";
import { profileItemDivider } from "../../../constants/styles";
import { Education } from "../../../generated/graphqlTypes";
import { useModalState } from "../../../hooks/dialogHooks";
import { sortByStartEndDates, splitArray } from "../../../utils/utils";
import BoxLayout from "../../BoxLayout";
import HeaderWithActions from "../../HeaderWithActions";
import SectionWithHoverEdit from "../../SectionWithHoverEdit";
import EducationModal from "./EducationModal";

const visibleEduNum = 3;

type EducationSectionProps = {
  education: Education[];
  isCurrentUser: boolean;
};

const EducationSection: React.FC<EducationSectionProps> = ({ education, isCurrentUser }) => {
  const { classes } = useStyles();
  const [open, handleOpen, handleClose] = useModalState();
  const [educationToUpdate, setEducationToUpdate] = useState<Education>();

  const sortedEducation = useMemo(() => {
    return sortByStartEndDates(education);
  }, [education]);

  const [visibleEdu, expandableEdu] = splitArray(sortedEducation, visibleEduNum);

  const eduNodes = (education: Education[]) =>
    education.map((edu, index) => (
      <div key={index}>
        <SectionWithHoverEdit
          key={index}
          onIconClick={() => {
            setEducationToUpdate(edu);
            handleOpen();
          }}
          hideEdit={!isCurrentUser}
        >
          <div className={classes.item}>
            <div className={classes.itemDetails}>
              <Typography variant="h6">{edu.institution}</Typography>
              <Typography color="textSecondary">
                {findLabel(EDUCATION_DEGREE_OPTIONS, edu.degree)}
                {edu.field ? `, ${edu.field}` : ""}
                {edu.grade ? `, ${edu.grade}` : ""}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                {format(new Date(edu.startDate), "MMM yyyy")} -{" "}
                {format(new Date(edu.endDate), "MMM yyyy")}
              </Typography>
              <Typography className={classes.itemDescription} gutterBottom>
                {edu.description}
              </Typography>
            </div>
          </div>
        </SectionWithHoverEdit>
        {index != education.length - 1 ? (
          <Divider variant="fullWidth" className={classes.divider} />
        ) : null}
      </div>
    ));

  return (
    <>
      <BoxLayout
        expandableArea={
          !!expandableEdu.length && (
            <>
              <Divider
                variant="fullWidth"
                className={`${classes.divider} ${classes.showMoreDivider}`}
              />
              {eduNodes(expandableEdu)}
            </>
          )
        }
      >
        <HeaderWithActions
          title="Education"
          actions={isCurrentUser && ["add"]}
          actionHandlers={[() => handleOpen()]}
        />
        {eduNodes(visibleEdu)}
      </BoxLayout>
      <EducationModal
        open={open}
        onClose={handleClose}
        defaultEducation={educationToUpdate}
        onExited={() => setEducationToUpdate(null)}
      />
    </>
  );
};

const itemMarginRight = 2;

export const useStyles = makeStyles()((theme) => ({
  item: {
    display: "flex"
  },
  itemDetails: {
    flex: 1,
    marginRight: theme.spacing(itemMarginRight)
  },
  itemDescription: {
    whiteSpace: "pre-line"
  },
  divider: {
    ...profileItemDivider(theme)
  },
  showMoreDivider: {
    marginRight: `calc(48px + ${theme.spacing(itemMarginRight)})`
  }
}));

export default EducationSection;
