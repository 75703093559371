import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { FormikAutocomplete, FormikTextField } from "@placehires/react-component-library";
import { useField } from "formik";
import React from "react";
import { Certification } from "../../../generated/graphqlTypes";
import StartEndDates from "../../input/StartEndDates";

export type CertificationsFormProps = {
  certifications: Certification[];
  loadingCertifications: boolean;
  isEdit?: boolean;
};

const CertificationsForm: React.FC<CertificationsFormProps> = ({
  certifications,
  loadingCertifications,
  isEdit
}) => {
  const [expirationDateField, , { setValue }] = useField("expirationDate");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormikAutocomplete
          disabled={isEdit}
          required
          fullWidth
          name="certification"
          loading={loadingCertifications}
          options={certifications || []}
          getOptionLabel={(certification) => certification.name}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField required fullWidth name="issuingOrganization" />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!expirationDateField.value}
              onChange={(_, v) => (v ? setValue(null) : setValue(new Date()))}
            />
          }
          label="This credential does not expire"
        />
      </Grid>
      <StartEndDates
        startDateProps={{
          name: "issueDate",
          required: !!expirationDateField.value
        }}
        endDateProps={{
          name: "expirationDate",
          noMaxDate: true
        }}
      />
      <Grid item xs={12}>
        <FormikTextField fullWidth name="credentialID" />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField fullWidth name="credentialURL" />
      </Grid>
    </Grid>
  );
};

export default CertificationsForm;
