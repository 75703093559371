import { arrayMoveImmutable } from "array-move";
import { clamp, distance } from "popmotion";
import { useEffect, useRef, useState } from "react";

const buffer = 30;

export interface Position {
  top: number;
  height: number;
}

export function usePositionReorder<T>(initialState: T[]) {
  const [order, setOrder] = useState(initialState);

  useEffect(() => {
    setOrder(initialState);
  }, [initialState]);

  // We need to collect an array of height and position data for all of this component's
  // `Item` children, so we can later us that in calculations to decide when a dragging
  // `Item` should swap places with its siblings.
  const positions: Position[] = useRef([]).current;
  const updatePosition = (index: number, position: Position) => {
    positions[index] = position;
  };

  // Find the ideal index for a dragging item based on its position in the array, and its
  // current drag offset. If it's different to its current index, we swap this item with that
  // sibling.
  const updateOrder = (index: number, dragOffset: number) => {
    const targetIndex = findIndex(index, dragOffset, positions);
    if (targetIndex !== index) {
      setOrder(arrayMoveImmutable(order, index, targetIndex));
      return true;
    }
  };

  return [order, updatePosition, updateOrder] as const;
}

export const useMeasurePosition = (update: (position: Position) => void) => {
  // We'll use a `ref` to access the DOM element that the `motion.li` produces.
  // This will allow us to measure its height and position, which will be useful to
  // decide when a dragging element should switch places with its siblings.
  const ref = useRef<HTMLLIElement>(null);

  // Update the measured position of the item so we can calculate when we should rearrange.
  useEffect(() => {
    if (ref.current) {
      update({
        height: ref.current.offsetHeight,
        top: ref.current.offsetTop
      });
    }
  });

  return ref;
};

const findIndex = (index: number, yOffset: number, positions: Position[]) => {
  let target = index;
  const { top, height } = positions[index];
  const bottom = top + height;

  // If moving down
  if (yOffset > 0) {
    const nextItem = positions[index + 1];
    if (nextItem === undefined) return index;

    const swapOffset = distance(bottom, nextItem.top + nextItem.height / 2) + buffer;
    if (yOffset > swapOffset) target = index + 1;

    // If moving up
  } else if (yOffset < 0) {
    const prevItem = positions[index - 1];
    if (prevItem === undefined) return index;

    const prevBottom = prevItem.top + prevItem.height;
    const swapOffset = distance(top, prevBottom - prevItem.height / 2) + buffer;
    if (yOffset < -swapOffset) target = index - 1;
  }

  return clamp(0, positions.length, target);
};
