import { useNotificationsLazyQuery, useReadNotificationsMutation } from "../generated/graphqlHooks";
import { getPathname } from "../utils/browserUtils";
import { useUser } from "./authHooks";

export const useReadNotificationByPath = (defaultReadPath?: string) => {
  const user = useUser();

  const [loadQuery] = useNotificationsLazyQuery();
  const [readNotification] = useReadNotificationsMutation();

  return async (pathname = defaultReadPath) => {
    if (!user) return;
    const notifications =
      (
        await loadQuery({
          variables: {
            receiver: user?.uid
          }
        })
      ).data.notifications || [];
    const notificationToRead = notifications.find(
      (notification) => !notification.read && pathname === getPathname(notification.redirectLink)
    );
    if (notificationToRead) {
      return readNotification({ variables: { id: notificationToRead._id } });
    }
  };
};
