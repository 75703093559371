import { RouteComponentProps } from "@reach/router";
import React from "react";
import ApplicantInterviewScheduler from "../components/interviews/ApplicantInterviewScheduler";
import RecruiterInterviewScheduler from "../components/interviews/RecruiterInterviewScheduler";
import NotFoundComponent from "../components/NotFoundComponent";
import { APPLICANT_ROLE, RECRUITER_ROLE } from "../constants/general";
import withAuthentication, { AuthInjectedProps } from "../hocs/withAuthentication";
import PrivateLayout from "../layout/PrivateLayout";

const Interviews: React.FC<RouteComponentProps & AuthInjectedProps> = ({ user }) => {
  return (
    <PrivateLayout disableGuttersMobile>
      {user.role === APPLICANT_ROLE ? (
        <ApplicantInterviewScheduler />
      ) : user.role === RECRUITER_ROLE ? (
        <RecruiterInterviewScheduler />
      ) : (
        <NotFoundComponent />
      )}
    </PrivateLayout>
  );
};

export default withAuthentication(Interviews);
