import { Avatar, Grid, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { Link } from "gatsby";
import React from "react";
import { PREVIEW_AVATAR_DIMENSIONS } from "../../constants/styles";
import { ApplicantPreviewFieldsFragment } from "../../generated/graphqlTypes";
import BoxLayout from "../BoxLayout";

type ApplicationPreviewProps = {
  application: {
    applicant?: ApplicantPreviewFieldsFragment;
  };
};

const ApplicationPreview: React.FC<ApplicationPreviewProps> = ({ application: { applicant } }) => {
  const { classes } = useStyles();

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Link to={`/app/${applicant._id}`} className={classes.linkWrapper}>
        <BoxLayout className={classes.studentInfo} elevation={4} withoutMargin>
          <Avatar src={applicant.avatarURL} className={classes.avatar} />
          <div className={classes.studentName}>
            <Typography variant="h6">{applicant.fullName}</Typography>
          </div>
        </BoxLayout>
      </Link>
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => ({
  linkWrapper: {
    textDecoration: "none",
    color: "unset"
  },
  studentInfo: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    ...PREVIEW_AVATAR_DIMENSIONS
  },
  studentName: {
    marginLeft: theme.spacing(3)
  }
}));

export default ApplicationPreview;
