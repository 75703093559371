import { OperationVariables, TypedDocumentNode, useApolloClient } from "@apollo/client";
import { useCallback } from "react";

/** A lazy query that will return result as a promise */
export function useCustomLazyQuery<TData = any, TVariables = OperationVariables>(
  query: TypedDocumentNode<TData, TVariables>
) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) =>
      client
        .query<TData, TVariables>({
          query,
          variables: variables
        })
        .then((res) => res.data),
    [client, query]
  );
}
