import { Grid } from "@mui/material";
import { FormikTextField } from "@placehires/react-component-library";
import React from "react";
import { EDUCATION_DEGREE_OPTIONS } from "../../../constants/general";
import StartEndDates from "../../input/StartEndDates";

const EducationForm: React.FC = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormikTextField required name="institution" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          required
          select
          fullWidth
          name="degree"
          SelectProps={{
            options: EDUCATION_DEGREE_OPTIONS
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField name="field" fullWidth />
      </Grid>
      <StartEndDates endDateProps={{ label: "End Date (or expected)", noMaxDate: true }} />
      <Grid item xs={12}>
        <FormikTextField name="grade" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField name="description" fullWidth multiline rows={4} />
      </Grid>
    </Grid>
  );
};

export default EducationForm;
