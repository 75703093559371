import {
  findSelectedOption,
  FormikDialog,
  getInitialValues,
  updateCacheAfterAdd,
  updateCacheAfterRemove
} from "@placehires/react-component-library";
import React from "react";
import {
  useAddApplicantExperienceMutation,
  useApplicantQuery,
  useAutocompleteCompaniesQuery,
  useDeleteApplicantExperienceMutation,
  useUpdateApplicantExperienceMutation
} from "../../../generated/graphqlHooks";
import { ApplicantDocument, EmploymentType, Experience } from "../../../generated/graphqlTypes";
import withAuthentication, { AuthInjectedProps } from "../../../hocs/withAuthentication";
import { useIndustryPositions } from "../../../hooks/positionHooks";
import ExperiencesForm from "./ExperiencesForm";

interface ExperiencesModalProps extends AuthInjectedProps {
  open: boolean;
  onClose: () => void;
  onExited: () => void;
  defaultExperience: Experience;
}

const ExperiencesModal: React.FC<ExperiencesModalProps> = ({
  open,
  onClose,
  defaultExperience,
  onExited,
  user
}) => {
  const { data: applicantData } = useApplicantQuery({
    variables: { id: user.uid }
  });
  const { data: companiesData } = useAutocompleteCompaniesQuery();
  const [addExperience] = useAddApplicantExperienceMutation();
  const [updateExperience] = useUpdateApplicantExperienceMutation();
  const [deleteExperience] = useDeleteApplicantExperienceMutation();

  const { data: positionsData, loading } = useIndustryPositions(
    applicantData?.applicant?.industryId
  );

  return (
    <FormikDialog
      open={open}
      onClose={onClose}
      onExited={onExited}
      title={`${defaultExperience ? "Edit" : "Add"} Experience`}
      formikConfig={{
        initialValues: getInitialValues(
          {
            position: "",
            description: "",
            company: null,
            companyName: "",
            employmentType: "" as EmploymentType,
            startDate: new Date(),
            endDate: new Date()
          },
          defaultExperience && {
            ...defaultExperience,
            company: defaultExperience.companyId
              ? findSelectedOption(defaultExperience.companyId, companiesData.companies, "_id")
              : null
          }
        ),
        enableReinitialize: true,
        onSaveSubmit: async (values) => {
          const { company, companyName, ...fields } = values;
          const experienceFields = {
            ...fields,
            company: company?.name === companyName ? company._id : companyName
          };
          if (!defaultExperience) {
            await addExperience({
              variables: {
                experienceFields
              },
              update: (cache, mutationResult) => {
                updateCacheAfterAdd(
                  cache,
                  ApplicantDocument,
                  mutationResult.data.addApplicantExperience,
                  {
                    queryVariables: { id: user.uid },
                    toUpdateListName: "experience"
                  }
                );
              }
            });
          } else {
            await updateExperience({
              variables: {
                id: defaultExperience._id,
                experienceFields
              }
            });
          }
        },
        onDeleteSubmit:
          defaultExperience &&
          (() => {
            return deleteExperience({
              variables: {
                id: defaultExperience._id
              },
              update: (cache) => {
                updateCacheAfterRemove(cache, ApplicantDocument, defaultExperience._id, {
                  queryVariables: { id: user.uid },
                  toUpdateListName: "experience"
                });
              }
            });
          })
      }}
    >
      <ExperiencesForm positions={positionsData?.positions} loadingPositions={loading} />
    </FormikDialog>
  );
};

export default withAuthentication(ExperiencesModal);
