import { Card, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { Application, Job } from "../../generated/graphqlTypes";
import { BOX_DEFAULT_ELEVATION } from "../BoxLayout";
import JobIconDetails from "./JobIconDetails";

type ApplicantJobPreviewProps = {
  job: Pick<Job, "company" | "position" | "employmentType" | "location">;
  isSelected?: boolean;
  onClick?: () => void;
  applicationInfo?: Pick<Application, "status" | "updatedAt">;
  withMb?: boolean;
};

const ApplicantJobPreview: React.FC<ApplicantJobPreviewProps> = ({
  job,
  onClick,
  applicationInfo,
  isSelected,
  withMb
}) => {
  return (
    <Card
      elevation={BOX_DEFAULT_ELEVATION}
      onClick={onClick}
      sx={{
        p: "1rem 1.5rem",
        bgcolor: isSelected ? "primary.main" : "background.paper",
        color: isSelected ? "primary.contrastText" : "inherit",
        cursor: "pointer",
        mb: withMb ? 2 : 0,
        transition: "background-color 0.2s ease-in-out"
      }}
    >
      <Typography variant="h5" fontWeight={800} mb={2}>
        {job.position.name}
      </Typography>
      <Divider sx={{ bgcolor: "primary.contrastText", mb: 2, mx: -3 }} />
      <JobIconDetails
        avatarURL={job.company.avatarURL}
        companyName={job.company.name}
        employmentType={job.employmentType}
        location={job.location}
        applicationInfo={applicationInfo}
      />
    </Card>
  );
};

export default ApplicantJobPreview;
