import { Close, CloudUploadOutlined } from "@mui/icons-material";
import { Box, Button, IconButton, Link, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React, { useState } from "react";
import { ReactComponent as PdfSvg } from "../../assets/icons/pdfFile.svg";
import { CARD_BORDER_RADIUS } from "../../gatsby-theme-material-ui-top-layout/theme";
import { useRemoveApplicantResumeMutation } from "../../generated/graphqlHooks";
import BoxLayout from "../BoxLayout";
import UploadFilesModal from "./UploadFilesModal";

interface GeneralUserInfoProps {
  resumeUrl: string;
  isCurrentUser?: boolean;
}

const FileSection: React.FC<GeneralUserInfoProps> = ({ resumeUrl, isCurrentUser }) => {
  const { classes } = useStyles();
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [removeResume, { loading: loadingRemoveResume }] = useRemoveApplicantResumeMutation();

  return (
    <BoxLayout>
      <Typography variant="h4" color="primary" flexGrow={1} lineHeight={1} mb="1rem">
        Supporting Documents
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mb: -2,
          ".upload-box": {
            display: "flex",
            alignItems: "center",
            border: "2px dashed rgba(0, 0, 0, 0.1)",
            padding: "0.25rem 0.5rem",
            borderRadius: CARD_BORDER_RADIUS,
            bgcolor: "rgba(196, 196, 196, 0.5)",
            mr: 2,
            mb: 2
          }
        }}
      >
        {resumeUrl && (
          <div className="upload-box">
            <Link
              href={resumeUrl}
              target="_blank"
              className={classes.fileWrapper}
              color="inherit"
              underline="none"
              sx={{ flex: "1 1 0" }}
            >
              <PdfSvg className={classes.pdfIcon} />
              <Typography variant="h6">Resume.pdf</Typography>
            </Link>

            {isCurrentUser && (
              <IconButton
                size="small"
                onClick={() => removeResume()}
                disabled={loadingRemoveResume}
              >
                <Close fontSize="small" color="error" />
              </IconButton>
            )}
          </div>
        )}
        {isCurrentUser && (
          <Box mb={2}>
            <Button variant="contained" color="primary" onClick={() => setOpenUploadFile(true)}>
              <CloudUploadOutlined fontSize="small" sx={{ mr: 1 }} />
              Upload
            </Button>
          </Box>
        )}
      </Box>
      <UploadFilesModal open={openUploadFile} setOpen={setOpenUploadFile} />
    </BoxLayout>
  );
};

export const useStyles = makeStyles()((theme) => ({
  fileWrapper: {
    display: "flex",
    alignItems: "center",
    width: "max-content",
    marginRight: theme.spacing(2)
  },
  pdfIcon: {
    width: 20,
    height: 20,
    fill: theme.palette.error.main,
    marginRight: theme.spacing(1)
  }
}));

export default FileSection;
