import { EditOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";

type SectionWithHoverEditProps = {
  children: React.ReactNode;
  onIconClick: () => void;
  hideEdit?: boolean;
  hoverIconClassName?: string;
};

const SectionWithHoverEdit: React.FC<SectionWithHoverEditProps> = ({
  children,
  onIconClick,
  hideEdit,
  hoverIconClassName
}) => {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>{children}</div>

      {!hideEdit && (
        <div className={cx(classes.hoverIcon, hoverIconClassName)}>
          <IconButton onClick={onIconClick} size="small">
            <EditOutlined color="primary" fontSize="small" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles<void, "hoverIcon">()((theme, props, classes) => ({
  wrapper: {
    position: "relative",
    display: "flex",
    "&:hover": {
      [`& .${classes.hoverIcon}`]: {
        opacity: 1
      }
    }
  },
  content: {
    flex: 1
  },
  hoverIcon: {
    position: "relative",
    right: theme.spacing(-1),
    opacity: 0
  }
}));

export default SectionWithHoverEdit;
