import { CheckCircle } from "@mui/icons-material";
import { Button, Container, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import BoxLayout from "../BoxLayout";

type QuestionnaireStartCoverProps = {
  onContinue: () => void;
  forRecruiter?: boolean;
  progress: number;
};

const QuestionnaireStartCover: React.FC<QuestionnaireStartCoverProps> = ({
  onContinue,
  progress,
  forRecruiter
}) => {
  const { classes } = useStyles();

  const iconTitle = (title: string) => {
    return (
      <div className={classes.iconTitle}>
        <CheckCircle color="primary" />
        <Typography variant="h4" display="inline" className={classes.title}>
          {title}
        </Typography>
      </div>
    );
  };

  return (
    <Container maxWidth="md" disableGutters>
      <BoxLayout className={classes.root}>
        <Typography variant="h3" color="primary" align="center">
          Matching Questionnaire
        </Typography>
        <Typography variant="subtitle1" align="center">
          The following questions are to help us provide you with better matches
        </Typography>
        {iconTitle("Getting started")}
        <Typography>
          Our questionnaire is designed to ensure that you have the most seamless working experience
          with {forRecruiter ? "applicants" : "restaurants"}. Please make sure to answer as honest
          as you can to find the right fit for you. Make sure you are using a tablet or a computer.
        </Typography>
        {iconTitle("Overview")}
        <Typography gutterBottom>
          Our questionnaire lasts 15 minutes, consisting of multiple choice questions. Your answers
          will be saved after each page.
        </Typography>
        <Typography color="primary">
          Note: Questionnaire can only be changed every 3 months.
        </Typography>
        <div className={classes.buttonWrapper}>
          <Button color="primary" variant="contained" size="large" onClick={onContinue}>
            {progress === 0 ? "Start" : "Resume"} Questionnaire
          </Button>
        </div>
      </BoxLayout>
    </Container>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(-2),
    padding: theme.spacing(4, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4)
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6, 8)
    }
  },
  iconTitle: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  title: {
    marginLeft: theme.spacing(1)
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4)
  }
}));

export default QuestionnaireStartCover;
