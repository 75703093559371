import { Button, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { addMinutes } from "date-fns";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useEffect, useState } from "react";
import { dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  useCreateInterviewMutation,
  useInterviewsForJobQuery,
  useJobWithInterviewQuery,
  useUpdateInterviewDetailsMutation
} from "../../generated/graphqlHooks";
import BoxLayout from "../BoxLayout";
import PageTitle from "../PageTitle";
import Scheduler, { Event } from "./Scheduler";
import TimeRangePicker from "./TimeRangePicker";

// Note: Currently, only interview details can be editted front to back when the "Save" button is clicked
//       New interview slots are created everytime we "Save"

const locales = {
  "en-US": enUS
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

const RecruiterInterviewScheduler = () => {
  const { classes } = useStyles();

  // TODO: get jobId from previous job page or wherever this page is supposed to be called from
  const jobId = "61479aee88c7f707848d05fc";

  // Queries
  const { data: jobData } = useJobWithInterviewQuery({ variables: { id: jobId } });
  const { data: interviewsData } = useInterviewsForJobQuery({ variables: { jobId: jobId } });

  // Mutations
  const [updateInterviewDetails] = useUpdateInterviewDetailsMutation();
  const [createInterviewSlot] = useCreateInterviewMutation();

  const [events, setEvents] = useState<Event[]>([]);

  // Fields to help with interview slots creation
  const [workHours, setWorkHours] = useState<[string, string]>(["09:00", "17:00"]);
  const [interviewDuration, setInterviewDuration] = useState(10);
  const [bufferTime, setBufferTime] = useState(0);

  // Interview Details Fields
  const [minimumNotice, setMinimumNotice] = useState(1);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");

  // Localization
  // const [timeLocation, setTimeLocation] = useState(0);

  useEffect(() => {
    if (jobData) {
      setMinimumNotice(jobData.job.interviewDetails.minimumNotice);
      setDescription(jobData.job.interviewDetails.description);
      setLocation(jobData.job.interviewDetails.location);
    }
    if (interviewsData) {
      // this data is grabbed to be used for editing later on
    }
  }, [jobData]);

  const handleSave = () => {
    const newEvents: Event[] = [];
    events.forEach((event) => {
      let start = new Date(event.start);
      const end = new Date(event.end);
      console.log("bruh");

      while (addMinutes(start, interviewDuration) <= end) {
        console.log("bruh");
        newEvents.push({
          start: start,
          end: addMinutes(start, interviewDuration)
        });
        start = addMinutes(start, interviewDuration + bufferTime);
      }
    });

    // Create new interview slot in the db
    newEvents.forEach((interview) =>
      createInterviewSlot({
        variables: {
          interviewFields: {
            start: new Date(interview.start),
            end: new Date(interview.end),
            jobId: jobId
          }
        }
      })
    );

    // Update interview detail for job
    updateInterviewDetails({
      variables: {
        id: jobId,
        interviewDetailsFields: {
          location: location,
          minimumNotice: minimumNotice,
          description: description
        }
      }
    });

    return newEvents;
  };

  return (
    <>
      <PageTitle title="Schedule Interviews" subtitle="Select your best time slots" />

      <BoxLayout>
        <div className={classes.cueText}>
          <Typography variant="h5" color="inherit">
            What is your availability?
          </Typography>
          <Typography variant="body1">
            Please click and drag your available time slots in the calendar.
          </Typography>
        </div>
        <Scheduler
          events={events}
          setEvents={setEvents}
          localizer={localizer}
          startHour={workHours[0]}
          endHour={workHours[1]}
        />

        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={4}>
            <Typography variant="h5">Work Hours</Typography>
            <TimeRangePicker selectedTimes={workHours} setSelectedTimes={setWorkHours} />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h5">Interview Duration</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                variant="outlined"
                value={interviewDuration}
                onChange={(event: any) => {
                  setInterviewDuration(event.target.value);
                }}
              >
                <MenuItem value={10}>10 minutes</MenuItem>
                <MenuItem value={15}>15 minutes</MenuItem>
                <MenuItem value={20}>20 minutes</MenuItem>
                <MenuItem value={30}>30 minutes</MenuItem>
                <MenuItem value={45}>45 minutes</MenuItem>
                <MenuItem value={60}>1 hour</MenuItem>
                <MenuItem value={120}>2 hours</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h5">Buffer Time</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                variant="outlined"
                value={bufferTime}
                onChange={(event: any) => {
                  setBufferTime(event.target.value);
                }}
              >
                <MenuItem value={0}>none</MenuItem>
                <MenuItem value={5}>5 minutes</MenuItem>
                <MenuItem value={10}>10 minutes</MenuItem>
                <MenuItem value={15}>15 minutes</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3} justifyContent="flex-end">
          <Grid item xs={4}>
            <Typography variant="h5">Description</Typography>
            <TextField
              id="description"
              type="text"
              placeholder="Description"
              variant="outlined"
              className={classes.textField}
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h5">Location</Typography>
            <TextField
              id="location"
              type="text"
              placeholder="Location"
              variant="outlined"
              className={classes.textField}
              value={location}
              onChange={(event) => {
                setLocation(event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="h5">Minimum notice</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                variant="outlined"
                value={minimumNotice}
                onChange={(event: any) => {
                  setMinimumNotice(event.target.value);
                }}
              >
                <MenuItem value={1}>1 Day</MenuItem>
                <MenuItem value={2}>2 Days</MenuItem>
                <MenuItem value={3}>3 Days</MenuItem>
                <MenuItem value={7}>1 Week</MenuItem>
                <MenuItem value={14}>2 Weeks</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <hr />

        <div className={classes.schedulerFooter}>
          <div className={classes.schedulerFooterInnerBox}>
            {/* <Typography variant="h6" color="inherit">
                All meeting times will be booked in: 
            </Typography> */}
            {/* <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Localization</InputLabel>
              <Select
                variant="outlined"
                value={timeLocation}
                onChange={(event: any) => {setTimeLocation(event.target.value);}}
                label="Localization"
              >
                <MenuItem value={0}>Eastern Time - US & CANADA</MenuItem>
                <MenuItem value={1}>Whatever Time</MenuItem>
                <MenuItem value={2}>Other Time</MenuItem>
              </Select>
            </FormControl> */}
          </div>
          <Button
            className={classes.saveButton}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </BoxLayout>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  saveButton: {
    width: 100,
    height: 50
  },
  cueText: {
    marginBottom: "20px"
  },
  schedulerFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  schedulerFooterInnerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200
  },
  textField: {
    margin: "0.5rem"
  }
}));

export default RecruiterInterviewScheduler;
