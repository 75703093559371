import { RouteComponentProps } from "@reach/router";
import React from "react";
import ApplicantDashboard from "../components/dashboard/ApplicantDashboard";
import RecruiterDashboard from "../components/dashboard/RecruiterDashboard";
import NotFoundComponent from "../components/NotFoundComponent";
import { APPLICANT_ROLE, RECRUITER_ROLE } from "../constants/general";
import withAuthentication, { AuthInjectedProps } from "../hocs/withAuthentication";
import PrivateLayout from "../layout/PrivateLayout";

const Dashboard: React.FC<RouteComponentProps & AuthInjectedProps> = ({ user }) => {
  return (
    <PrivateLayout disableGuttersMobile>
      {user.role === APPLICANT_ROLE ? (
        <ApplicantDashboard />
      ) : user.role === RECRUITER_ROLE ? (
        <RecruiterDashboard />
      ) : (
        <NotFoundComponent />
      )}
    </PrivateLayout>
  );
};

export default withAuthentication(Dashboard);
