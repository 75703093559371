import { Avatar } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React, { useRef, useState } from "react";
import { handleImagePreview } from "../../utils/inputUtils";

export type AvatarSelectProps = {
  avatarURL: string;
  setAvatar?: (image: File) => void;
  variant?: "rounded" | "square"; // Defaults to circle
};

const AvatarSelect: React.FC<AvatarSelectProps> = ({ avatarURL, setAvatar, variant }) => {
  const [avatarPreview, setAvatarPreview] = useState(null);
  const avatarInput = useRef(null);
  const { classes } = useStyles({ setAvatar, avatarURL });

  const handleClick = () => avatarInput.current.click();

  return (
    <div className={classes.root}>
      <Avatar
        className={classes.avatar}
        src={avatarPreview || avatarURL}
        variant={variant}
        onClick={handleClick}
      />
      <input
        hidden
        ref={avatarInput}
        type="file"
        accept="image/*"
        onChange={(e) => handleImagePreview(e.target.files[0], setAvatar, setAvatarPreview)}
      />
    </div>
  );
};

const useStyles = makeStyles<Partial<AvatarSelectProps & { isCurrentUser: boolean }>>()(() => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "100%"
  },
  avatar: {
    position: "relative",
    cursor: "pointer",
    width: 120,
    height: 120,
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)"
  }
}));

export default AvatarSelect;
