import { Grid } from "@mui/material";
import {
  findSelectedOption,
  FormikDialog,
  FormikTextField
} from "@placehires/react-component-library";
import { omit } from "lodash";
import React from "react";
import * as yup from "yup";
import { COMPANY_SIZE_OPTIONS } from "../../../constants/general";
import { useIndustriesQuery, useUpdateCompanyMutation } from "../../../generated/graphqlHooks";
import { Company } from "../../../generated/graphqlTypes";
import IndustryAutocomplete from "../../input/IndustryAutocomplete";

type CompanyOverviewModalProps = {
  defaultValues: Company;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const CompanyOverviewModal: React.FC<CompanyOverviewModalProps> = ({
  defaultValues,
  open,
  setOpen
}) => {
  const [updateCompany] = useUpdateCompanyMutation();

  const { data: industriesData } = useIndustriesQuery();

  const handleClose = () => setOpen(false);

  return (
    <FormikDialog
      open={open}
      onClose={handleClose}
      title="Edit Company Overview"
      formikConfig={{
        initialValues: {
          ...omit(defaultValues, ["_id", "industryName"]),
          industry: findSelectedOption(defaultValues.industryId, industriesData?.industries, "_id")
        },
        validationSchema: yup.object().shape({
          website: yup.string().url("Please provide a valid URL").nullable()
        }),
        onSaveSubmit: async (values) => {
          const { industry, companySize, ...basicInfo } = values;
          await updateCompany({
            variables: {
              id: defaultValues._id,
              basicInfo: {
                ...basicInfo,
                companySize: Number(companySize),
                industryId: industry._id
              }
            }
          });
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikTextField required fullWidth name="overview" multiline rows={4} />
        </Grid>
        <Grid item xs={12}>
          <IndustryAutocomplete required />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField name="website" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            select
            required
            fullWidth
            name="companySize"
            SelectProps={{
              options: COMPANY_SIZE_OPTIONS
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField name="headquarters" fullWidth />
        </Grid>
      </Grid>
    </FormikDialog>
  );
};

export default CompanyOverviewModal;
