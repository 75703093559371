import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { GlobalStyles } from "@mui/material";
import { ConfirmProvider } from "@placehires/react-component-library";
import { Router } from "@reach/router";
import React, { memo } from "react";
import ErrorBoundaryWithNavigation from "../components/errorBoundaries/ErrorBoundaryWithNavigation";
import FullLayoutFallback from "../components/errorBoundaries/fallbacks/FullLayoutFallback";
import NotFoundComponent from "../components/NotFoundComponent";
import { APP_BACKGROUND } from "../constants/styles";
import withAuthenticationProvider from "../hocs/providers/app/withAuthenticationProvider";
import withGlobalContextProvider from "../hocs/providers/app/withGlobalContextProvider";
import { AuthInjectedProps } from "../hocs/withAuthentication";
import { useProcessCheckout } from "../hooks/stripeHooks";
import Application from "../private-pages/Applications";
import Company from "../private-pages/Company";
import CreateJob from "../private-pages/CreateJob";
import Dashboard from "../private-pages/Dashboard";
import Interviews from "../private-pages/Interviews";
import Job from "../private-pages/Job";
import Jobs from "../private-pages/Jobs";
import Profile from "../private-pages/Profile";
import Questionnaire from "../private-pages/Questionnaire";
import UpcomingInterviews from "../private-pages/UpcomingInterviews";

const App: React.FC<AuthInjectedProps> = memo(() => {
  useProcessCheckout();

  return (
    /**
     * TODO: remove
     * @ts-expect-error - Temporary Fix c.f. https://github.com/getsentry/sentry-javascript/issues/4904#issuecomment-1093336029
     */
    <ErrorBoundaryWithNavigation fallback={<FullLayoutFallback authenticated />}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ConfirmProvider>
          <GlobalStyles styles={`body { background-color: ${APP_BACKGROUND} !important;`} />
          <Router basepath="/app">
            <Application path="/applications" />
            <Dashboard path="/dashboard" />
            <CreateJob path="/jobs/create" />
            <Interviews path="/interviews" />
            <Jobs path="/jobs" />
            <Job path="/jobs/:jobId" />
            <Questionnaire path="/questionnaire" />
            <UpcomingInterviews path="/upcoming-interviews" />
            <Company path="/companies/:cid" />
            <Profile path="/:uid" />
            <Profile path="/" />
            <NotFoundComponent default />
          </Router>
        </ConfirmProvider>
      </LocalizationProvider>
    </ErrorBoundaryWithNavigation>
  );
});

export default withGlobalContextProvider(withAuthenticationProvider(App));
