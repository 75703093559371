import { Add, EditRounded } from "@mui/icons-material";
import { Button, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";

type ActionOptions = "edit" | "add" | string; // pass string to have a button

type HeaderWithActionProps = {
  title: string;
  actions?: Array<ActionOptions>;
  actionHandlers?: Array<() => void>;
};

const HeaderWithActions: React.FC<HeaderWithActionProps> = ({
  title,
  actions = [],
  actionHandlers = []
}) => {
  const { classes } = useStyles();

  return (
    <header className={classes.header}>
      <Typography variant="h4" color="primary" flexGrow={1} lineHeight={1} gutterBottom>
        {title}
      </Typography>
      {actions && (
        <div className={classes.actionArea}>
          {actions.map((action, index) =>
            action === "add" || action === "edit" ? (
              <IconButton onClick={actionHandlers[index]} key={index} size="small">
                {action == "edit" ? (
                  <EditRounded color="primary" fontSize="medium" />
                ) : (
                  <Add color="primary" fontSize="medium" />
                )}
              </IconButton>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                onClick={actionHandlers[index]}
                key={index}
              >
                {action}
              </Button>
            )
          )}
        </div>
      )}
    </header>
  );
};

const useStyles = makeStyles()((theme) => ({
  header: {
    position: "relative",
    display: "flex",
    marginBottom: theme.spacing(1.5)
  },
  actionArea: {
    position: "relative",
    right: theme.spacing(-1),
    top: -6
  }
}));

export default HeaderWithActions;
