import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { useRecruiterViewableJobApplicationsQuery } from "../../generated/graphqlHooks";
import BoxLayout from "../BoxLayout";
import LoadingComponent from "../LoadingComponent";
import ApplicationPreview from "./RecruiterApplicationPreview";

type JobApplicationsProps = {
  jobId: string;
};

const JobApplications: React.FC<JobApplicationsProps> = ({ jobId }) => {
  const { data, loading, error } = useRecruiterViewableJobApplicationsQuery({
    variables: { jobId }
  });

  if (loading) return <LoadingComponent />;
  if (error && !data) return <Typography variant="h2">Oops, something went wrong</Typography>;

  return (
    <>
      {data && !data.recruiterViewableJobApplications.length ? (
        <BoxLayout>
          <Typography variant="h6" align="center">
            This is where applications would appear
          </Typography>
        </BoxLayout>
      ) : (
        <Grid container spacing={2}>
          {data.recruiterViewableJobApplications.map((application) => (
            <ApplicationPreview application={application} key={application._id} />
          ))}
        </Grid>
      )}
    </>
  );
};

export default JobApplications;
