import { Grid } from "@mui/material";
import {
  findSelectedOptions,
  FormikAutocomplete,
  FormikCheckbox,
  FormikDialog,
  FormikTextField
} from "@placehires/react-component-library";
import React from "react";
import { EMPLOYMENT_TYPE_OPTIONS } from "../../../constants/general";
import { useUpdateApplicantJobPreferencesMutation } from "../../../generated/graphqlHooks";
import { JobPreferences, Position } from "../../../generated/graphqlTypes";

type JobPreferencesModalProps = {
  defaultJobPreferences: JobPreferences;
  loadingPositions: boolean;
  positions: Position[];
  open: boolean;
  onClose: () => void;
};

const JobPreferencesModal: React.FC<JobPreferencesModalProps> = ({
  open,
  onClose,
  defaultJobPreferences,
  positions,
  loadingPositions
}) => {
  const [updateJobPreferences] = useUpdateApplicantJobPreferencesMutation();
  const { positionsIds, ...defaultForm } = defaultJobPreferences || {};

  return (
    <FormikDialog
      open={open}
      onClose={onClose}
      title="Job Preferences"
      formikConfig={{
        initialValues: {
          ...defaultForm,
          positions: findSelectedOptions(positionsIds, positions, "_id")
        },
        onSaveSubmit: async ({ positions, ...jobPreferences }) => {
          await updateJobPreferences({
            variables: {
              jobPreferences: {
                ...jobPreferences,
                positionsIds: positions.map((pos) => pos._id)
              }
            }
          });
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikCheckbox label="I am currently seeking" name="seeking" />
        </Grid>
        <Grid item xs={12}>
          <FormikAutocomplete
            required
            fullWidth
            name="positions"
            multiple
            loading={loadingPositions}
            options={positions || []}
            getOptionLabel={(position) => position.name}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            select
            fullWidth
            name="jobTypes"
            SelectProps={{
              multiple: true,
              options: EMPLOYMENT_TYPE_OPTIONS
            }}
          />
        </Grid>
      </Grid>
    </FormikDialog>
  );
};

export default JobPreferencesModal;
