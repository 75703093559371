import React from "react";
import { useApplicantCreatedApplicationsQuery } from "../../generated/graphqlHooks";
import ApplicationsWithJobView from "../applications/ApplicationsWithJobView";

const ApplicantJobs = () => {
  const { data, loading } = useApplicantCreatedApplicationsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first"
  });

  return (
    <ApplicationsWithJobView
      title="Jobs"
      subtitle="View new job based on your job preferences"
      emptyDataText="This is where new jobs would appear"
      applications={data?.applicantCreatedApplications}
      loading={loading}
      getNavigationPath={(application) => application.job._id}
    />
  );
};
export default ApplicantJobs;
