import { Button, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { Link } from "gatsby";
import React from "react";
import BoxLayout from "../BoxLayout";
import CircularProgressWithLabel from "../progress/CircularProgressWithLabel";

type QuestionnaireBoxProps = {
  progressValue: number;
  fullHeight?: boolean;
};

const QuestionnaireBox: React.FC<QuestionnaireBoxProps> = ({ progressValue, fullHeight }) => {
  const { classes } = useStyles({ fullHeight });

  return (
    <BoxLayout className={classes.matchingApplicationWrapper}>
      <CircularProgressWithLabel
        value={progressValue}
        color="primary"
        backgroundColor="default"
        thickness={6}
      />
      <div className={classes.textWrapper}>
        <Typography variant="h5" color="primary" gutterBottom>
          Matching Application
        </Typography>
        <Typography>
          Complete the application for accurate matching. By fulfilling one <br />
          application, you can get matched with others.
        </Typography>
        <Button
          component={Link}
          to="/app/questionnaire"
          variant="contained"
          size="large"
          className={classes.questionnaireButton}
        >
          <Typography>{progressValue ? "Resume" : "Start"}</Typography>
        </Button>
      </div>
    </BoxLayout>
  );
};

const useStyles = makeStyles<Partial<QuestionnaireBoxProps>>()((theme, props) => ({
  matchingApplicationWrapper: {
    height: props.fullHeight ? "100%" : "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  textWrapper: {
    marginLeft: theme.spacing(2)
  },
  questionnaireButton: {
    marginTop: theme.spacing(2)
  }
}));

export default QuestionnaireBox;
