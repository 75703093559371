import { CircularProgress, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";

const defaultBackgroundColor = "#cccccc";

export type CircularProgressWithLabelProps = {
  /** The value of the progress indicator for the determinate and static variants. Value between 0 and 100. */
  value: number;
  /** The thickness of the circle in pixels. */
  thickness?: number;
  /** The size of the circle in pixels. */
  size?: number;
  /** Similar to size, but for xs screen (defaults to size) */
  xsSize?: number;
  /** Color of progress. Defaults to primary, */
  color?: "primary" | "secondary" | "inherit";
  /** Background color of progress. Use "default" for default gray color */
  backgroundColor?: "default" | string;
};

/** Circular progress with label and background color */
const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  value,
  size = 150,
  xsSize,
  thickness = 8,
  color,
  backgroundColor
}) => {
  if (backgroundColor === "default") backgroundColor = defaultBackgroundColor;
  const { classes } = useStyles({ backgroundColor });
  const xsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  if (xsScreen && xsSize) size = xsSize;

  return (
    <div className={classes.wrapper}>
      <CircularProgress
        variant="determinate"
        value={value}
        thickness={thickness}
        size={size}
        color={color}
        className={classes.progress}
      />
      <div className={classes.label}>
        <Typography variant="h5" component="div" color={color}>{`${Math.round(
          value
        )}%`}</Typography>
      </div>
      <CircularProgress
        variant="determinate"
        thickness={thickness}
        size={size}
        value={100}
        className={classes.backgroundCircle}
      />
    </div>
  );
};

const useStyles = makeStyles<Partial<CircularProgressWithLabelProps>>()((theme, props) => ({
  wrapper: {
    display: "inline-flex",
    position: "relative"
  },
  progress: {
    zIndex: 1
  },
  label: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  backgroundCircle: {
    position: "absolute",
    color: props.backgroundColor,
    ...(!props.backgroundColor && { display: "none" })
  }
}));

export default CircularProgressWithLabel;
