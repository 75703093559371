import { makeStyles } from "@placehires/react-component-library";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Position, useMeasurePosition, usePositionReorder } from "../../hooks/animationHooks";

export type ReorderableListProps<Item> = {
  items: Item[];
  getId: (item: Item) => string | number;
  renderItem: (item: Item) => React.ReactNode;
  className?: string;
  itemClassName?: string;
  onOrderChange?: (items: Item[]) => void;
  constraintRef?: React.MutableRefObject<HTMLElement>;
};

const ReorderableList = <Item,>({
  items,
  itemClassName,
  className,
  onOrderChange,
  getId,
  renderItem,
  constraintRef: customConstraintRef
}: ReorderableListProps<Item>) => {
  const { classes, cx } = useStyles();
  const [order, updatePosition, updateOrder] = usePositionReorder(items);
  const constraintRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (onOrderChange) onOrderChange(order);
  }, [order]); // eslint-disable-line

  return (
    <ul ref={constraintRef} className={cx(classes.list, className)}>
      {order.map((item, index) => (
        <ReorderableItem
          className={itemClassName}
          key={getId(item)}
          index={index}
          updatePosition={updatePosition}
          updateOrder={updateOrder}
          constraintRef={customConstraintRef || constraintRef}
          length={order.length}
        >
          {renderItem(item)}
        </ReorderableItem>
      ))}
    </ul>
  );
};

type ReorderableItemProps = {
  index: number;
  updatePosition: (index: number, position: Position) => void;
  updateOrder: (index: number, dragOffset: number) => void;
  children?: React.ReactNode;
  className?: string;
  // Defaults to 1, 1 (doesn't scale)
  scale?: {
    hover: number;
    tap: number;
  };
  constraintRef: React.MutableRefObject<HTMLElement>;
  length: number;
};

const ReorderableItem: React.FC<ReorderableItemProps> = ({
  index,
  updatePosition,
  updateOrder,
  children,
  className,
  scale = {
    hover: 1,
    tap: 1
  },
  length,
  constraintRef
}) => {
  const [isDragging, setDragging] = useState(false);
  const [layout, setLayout] = useState(true);

  const ref = useMeasurePosition((pos) => updatePosition(index, pos));

  useEffect(() => {
    setLayout(false);
    setTimeout(() => setLayout(true), 50);
  }, [length]);

  const recentlyScrolledRef = useRef(null);

  return (
    <motion.li
      className={className}
      ref={ref}
      layout={layout}
      initial={false}
      style={{
        zIndex: isDragging ? 3 : 1,
        cursor: "move"
      }}
      dragConstraints={constraintRef}
      dragElastic={0}
      whileHover={{
        scale: scale.hover,
        boxShadow: "0px 3px 3px rgba(0,0,0,0.15)"
      }}
      whileTap={{
        scale: scale.tap,
        boxShadow: "0px 5px 5px rgba(0,0,0,0.1)"
      }}
      drag="y"
      dragMomentum={false}
      onDragStart={() => setDragging(true)}
      onDragEnd={() => setDragging(false)}
      onDrag={(event) => {
        if (!("clientY" in event) || !ref.current || !constraintRef.current) return;
        const constraintEl = constraintRef.current;
        const { scrollTop, scrollHeight, clientHeight } = constraintEl;
        const { top, bottom } = constraintEl.getBoundingClientRect();
        const scrollTopMax = scrollHeight - clientHeight;
        const curY = event.clientY;
        const itemHeight = ref.current.offsetHeight;

        // Don't continue if recently scrolled
        if (recentlyScrolledRef.current) return;

        // Scroll up/down and move item up/down accordingly if drag position reaches the edge of
        // container (or goes over it)
        const topScrollStart = top + 10;
        const bottomScrollStart = bottom - 10;
        const scrollUp = curY <= topScrollStart && scrollTop > 0;
        const scrollDown = curY >= bottomScrollStart && scrollTop < scrollTopMax;
        if (scrollUp || scrollDown) {
          const offset = scrollUp ? -itemHeight : itemHeight;
          let scrollFreq = 50;
          // Scroll slower if dragging close to the edge of constraint container
          if (
            (curY <= topScrollStart && curY >= topScrollStart - 20) ||
            (curY >= bottomScrollStart && curY <= bottomScrollStart + 20)
          ) {
            scrollFreq = 200;
          }
          constraintEl.scrollTo({ top: scrollTop + offset });
          updateOrder(index, offset);
          recentlyScrolledRef.current = true;
          setTimeout(() => (recentlyScrolledRef.current = false), scrollFreq);
        }
      }}
      onViewportBoxUpdate={(_viewportBox, delta) => {
        isDragging && updateOrder(index, delta.y.translate);
      }}
    >
      {children}
    </motion.li>
  );
};

const useStyles = makeStyles()(() => ({
  list: {
    overflowY: "auto",
    listStyleType: "none",
    margin: 0,
    padding: 0
  }
}));

export default ReorderableList;
