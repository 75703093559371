import { FiberManualRecord } from "@mui/icons-material";
import { Box, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { findSelectedOptions } from "@placehires/react-component-library";
import { ExecutionResult } from "graphql";
import React, { useMemo } from "react";
import { Skill } from "../../../generated/graphqlTypes";
import { useModalState } from "../../../hooks/dialogHooks";
import { splitArray } from "../../../utils/utils";
import BoxLayout from "../../BoxLayout";
import HeaderWithActions from "../../HeaderWithActions";
import SkillsAddModal from "./SkillsAddModal";
import SkillsEditModal from "./SkillsEditModal";

const skillsSpacing = 3;
const visibleSkillsNum = 8;

type SkillsSectionProps = {
  skills: string[];
  allSkills: Skill[];
  isCurrentUser?: boolean;
  updateSkills: (skills: string[]) => Promise<ExecutionResult<any>>;
  loadingUpdate: boolean;
};

const SkillsSection: React.FC<SkillsSectionProps> = ({
  skills,
  isCurrentUser,
  allSkills,
  updateSkills,
  loadingUpdate
}) => {
  const [addOpen, openAdd, closeAdd] = useModalState();
  const [editOpen, openEdit, closeEdit] = useModalState();

  const skillsObjs = useMemo(
    () => findSelectedOptions(skills, allSkills, "_id"),
    [allSkills, skills]
  );
  if (!skills) skills = [];

  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));
  const visibleNum = isXs ? visibleSkillsNum / 2 : visibleSkillsNum;

  const [visibleSkills, expandableSkills] = splitArray(skillsObjs, visibleNum);

  const skillColumns = (skills: typeof skillsObjs) => (
    <Grid container columnSpacing={3} rowSpacing={1}>
      {skills.map(({ _id, name }) => (
        <Grid key={_id} item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
          <FiberManualRecord fontSize="small" color="primary" sx={{ fontSize: 8, mr: 1 }} />
          <Typography color="textSecondary">{name}</Typography>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <>
      <BoxLayout
        expandableArea={
          !!expandableSkills.length && (
            <Box mt={skillsSpacing / 2}>{skillColumns(expandableSkills)}</Box>
          )
        }
      >
        <HeaderWithActions
          title="Skills"
          actions={isCurrentUser && ["add", "edit"]}
          actionHandlers={[() => openAdd(), () => openEdit()]}
        />
        {skillColumns(visibleSkills)}
      </BoxLayout>
      <SkillsAddModal
        existingSkills={skills}
        open={addOpen}
        onClose={closeAdd}
        onSave={updateSkills}
      />
      <SkillsEditModal
        defaultSkills={skillsObjs}
        open={editOpen}
        onClose={closeEdit}
        onSave={updateSkills}
        loading={loadingUpdate}
      />
    </>
  );
};

export default SkillsSection;
