import { Avatar, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { FormikAutocomplete, FormikTextField } from "@placehires/react-component-library";
import { useField } from "formik";
import React from "react";
import { EMPLOYMENT_TYPE_OPTIONS, getCompanyAvatarURL } from "../../../constants/general";
import { useAutocompleteCompaniesQuery } from "../../../generated/graphqlHooks";
import { Position } from "../../../generated/graphqlTypes";
import StartEndDates from "../../input/StartEndDates";

export type ExperiencesFormProps = {
  positions: Position[];
  loadingPositions: boolean;
};

const ExperiencesForm: React.FC<ExperiencesFormProps> = ({ positions, loadingPositions }) => {
  const [endDateField, , { setValue }] = useField("endDate");
  const { data, loading } = useAutocompleteCompaniesQuery();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormikAutocomplete
          required
          freeSolo
          fullWidth
          disableClearable
          inputName="position"
          loading={loadingPositions}
          options={(positions || []).map((opt) => opt.name)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          required
          select
          fullWidth
          name="employmentType"
          SelectProps={{
            options: EMPLOYMENT_TYPE_OPTIONS
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikAutocomplete
          required
          name="company"
          inputName="companyName"
          loading={loading}
          options={data?.companies || []}
          getOptionLabel={(c) => c.name}
          getOptionId={(c) => c._id}
          freeSolo
          getOptionImage={(c) => <Avatar variant="square" src={getCompanyAvatarURL(c.avatarURL)} />}
          defaultOptionImage={<Avatar variant="square" src={getCompanyAvatarURL()} />}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField name="description" fullWidth multiline rows={5} />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!endDateField.value}
              onChange={(_, v) => (v ? setValue(null) : setValue(new Date()))}
            />
          }
          label="I am currently working here"
        />
      </Grid>
      <StartEndDates />
    </Grid>
  );
};

export default ExperiencesForm;
