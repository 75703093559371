import {
  AssignmentTurnedInOutlined,
  DateRange,
  EventAvailable,
  LocationOn,
  Mood,
  Schedule
} from "@mui/icons-material";
import { Avatar, Card, Hidden, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import LoadingComponent from "../components/LoadingComponent";
import { APPLICANT_ROLE, RECRUITER_ROLE } from "../constants/general";
import { LAYOUT_PADDING } from "../constants/styles";
import {
  useApplicantViewableApplicationsQuery,
  useRecruiterApplicationsByStatusQuery
} from "../generated/graphqlHooks";
import { Application, ApplicationStatus } from "../generated/graphqlTypes";
import withAuthentication, { AuthInjectedProps } from "../hocs/withAuthentication";

type UpcomingInterviewsProps = RouteComponentProps & AuthInjectedProps;

const UpcomingInterviews: React.FC<UpcomingInterviewsProps> = ({ user }) => {
  const { classes } = useStyles();
  const {
    data: applicantData,
    loading: applicantLoading,
    error: applicantError
  } = useApplicantViewableApplicationsQuery({ skip: user.role == RECRUITER_ROLE });
  const {
    data: selectedData,
    loading: selectedLoading,
    error: selectedError
  } = useRecruiterApplicationsByStatusQuery({
    variables: { status: ApplicationStatus.SELECTED_FOR_INTERVIEW },
    skip: user.role == APPLICANT_ROLE
  });
  const {
    data: interviewedData,
    loading: interviewedLoading,
    error: interviewedError
  } = useRecruiterApplicationsByStatusQuery({
    variables: { status: ApplicationStatus.INTERVIEWED },
    skip: user.role == APPLICANT_ROLE
  });
  const recruiterData =
    selectedData &&
    interviewedData &&
    selectedData.recruiterApplicationsByStatus.concat(
      interviewedData.recruiterApplicationsByStatus
    );

  const [atLeastOne, setAtLeastOne] = useState(false);

  const loading = applicantLoading || selectedLoading || interviewedLoading;
  const error = applicantError || selectedError || interviewedError;

  if (loading) return <LoadingComponent />;
  if (error) return <h1>{error.message}</h1>;

  return (
    <div className={classes.main}>
      <Typography variant="h4" gutterBottom>
        Upcoming Interviews
      </Typography>
      <Typography variant="caption">View your scheduled interviews' time and location.</Typography>

      {user.role == APPLICANT_ROLE
        ? applicantData &&
          applicantData.applicantViewableApplications.map(
            (application) =>
              (application.status === ApplicationStatus.SELECTED_FOR_INTERVIEW ||
                application.status === ApplicationStatus.INTERVIEWED) && (
                <InterviewCard
                  key={application._id}
                  application={application}
                  setAtLeastOne={() => setAtLeastOne(true)}
                  userRole={user.role}
                />
              )
          )
        : recruiterData &&
          recruiterData.map(
            (application) =>
              (application.status === ApplicationStatus.SELECTED_FOR_INTERVIEW ||
                application.status === ApplicationStatus.INTERVIEWED) && (
                <InterviewCard
                  key={application._id}
                  application={application}
                  setAtLeastOne={() => setAtLeastOne(true)}
                  userRole={user.role}
                />
              )
          )}

      {!atLeastOne && <h2>You have no interviews. Apply to jobs now!</h2>}
    </div>
  );
};

type InterviewCardProps = {
  application: Partial<Application>;
  setAtLeastOne: (atLeastOne: boolean) => void;
  userRole: string;
};

const InterviewCard: React.FC<InterviewCardProps> = ({
  application: {
    status,
    job: { positionId, location, company },
    applicant
  },
  setAtLeastOne,
  userRole
}) => {
  useEffect(() => {
    setAtLeastOne(true);
  });
  const { classes } = useStyles();
  const avatarURL = userRole == APPLICANT_ROLE ? company.avatarURL : applicant.avatarURL;
  const name =
    userRole == APPLICANT_ROLE ? company.name : `${applicant.firstName} ${applicant.lastName}`;

  return (
    <Card className={classes.cardMain}>
      <Typography variant="h6" gutterBottom>
        {positionId}
      </Typography>
      <div className={classes.mainContainer}>
        <div className={classes.interviewerContainer}>
          <Avatar src={avatarURL} />
          <Typography>{name}</Typography>
          {userRole == APPLICANT_ROLE && (
            <Typography variant="caption">INTERVIEWER_NAME</Typography>
          )}
        </div>
        <div className={classes.detailsContainer}>
          <div className={classes.details}>
            <DateRange color="primary" fontSize="small" className={classes.smallIcon} />
            <Typography color="textSecondary">ENTER_DATE_HERE</Typography>
          </div>
          <div className={classes.details}>
            <Schedule color="primary" fontSize="small" className={classes.smallIcon} />
            <Typography color="textSecondary">ENTER_TIME_HERE</Typography>
          </div>
          <div className={classes.details}>
            <LocationOn color="primary" fontSize="small" className={classes.smallIcon} />
            <Typography noWrap color="textSecondary">
              {location}
            </Typography>
          </div>
        </div>
        <div className={classes.optionsContainer}>
          {status === ApplicationStatus.SELECTED_FOR_INTERVIEW ? (
            <div className={classes.details}>
              <Hidden lgDown>
                <Typography color="textSecondary">Selected for Interview</Typography>
              </Hidden>
              <Mood className={`${classes.smallIcon} ${classes.green}`} />
            </div>
          ) : (
            <div className={classes.details}>
              <Hidden lgDown>
                <Typography color="textSecondary">Interviewed</Typography>
              </Hidden>
              <AssignmentTurnedInOutlined className={`${classes.green} ${classes.smallIcon}`} />
            </div>
          )}

          <div className={classes.details}>
            <Hidden lgDown>
              <Typography color="textSecondary">Add to my calendar</Typography>
            </Hidden>
            <IconButton size="small">
              <EventAvailable color="primary" className={classes.smallIcon} />
            </IconButton>
          </div>
        </div>
      </div>
    </Card>
  );
};

const useStyles = makeStyles()((theme) => ({
  main: {
    padding: theme.spacing(LAYOUT_PADDING)
  },
  cardMain: {
    padding: theme.spacing(LAYOUT_PADDING),
    marginTop: theme.spacing(LAYOUT_PADDING),
    maxWidth: theme.breakpoints.values.md
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  interviewerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: LAYOUT_PADDING
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: LAYOUT_PADDING
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  smallIcon: {
    margin: LAYOUT_PADDING
  },
  green: {
    color: theme.palette.success.main
  },
  details: {
    display: "flex",
    alignItems: "center"
  }
}));

export default withAuthentication(UpcomingInterviews);
