import { Grid } from "@mui/material";
import { RouteComponentProps } from "@reach/router";
import React from "react";
import CompanyProfile from "../components/profile/company/CompanyProfile";
import PrivateLayout from "../layout/PrivateLayout";

type CompanyProps = RouteComponentProps<{
  cid: string;
}>;

const Company: React.FC<CompanyProps> = ({ cid }) => {
  return (
    <PrivateLayout>
      <Grid container spacing={3}>
        <CompanyProfile cid={cid} />
      </Grid>
    </PrivateLayout>
  );
};

export default Company;
