import { RouteComponentProps } from "@reach/router";
import React from "react";
import ApplicantJobs from "../components/jobs/ApplicantJobs";
import RecruiterJobs from "../components/jobs/RecruiterJobs";
import { APPLICANT_ROLE } from "../constants/general";
import withAuthentication, { AuthInjectedProps } from "../hocs/withAuthentication";
import PrivateLayout from "../layout/PrivateLayout";

interface JobsProps extends RouteComponentProps, AuthInjectedProps {}

const Jobs: React.FC<JobsProps> = ({ user }) => {
  return (
    <PrivateLayout>
      {user.role == APPLICANT_ROLE ? <ApplicantJobs /> : <RecruiterJobs />}
    </PrivateLayout>
  );
};

export default withAuthentication(Jobs);
