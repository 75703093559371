import { SvgIconComponent } from "@mui/icons-material";
import { Button, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@placehires/react-component-library";
import React from "react";

type PageTitleProps = {
  title: string;
  subtitle?: string;
  action?: {
    text: string;
    icon?: SvgIconComponent;
    onClick?: () => void;
    link?: string;
    disabled?: boolean;
    disabledTooltipText?: string;
  };
  marginTop?: boolean;
};

const PageTitle: React.FC<PageTitleProps> = ({ title, subtitle, action, marginTop }) => {
  const { classes } = useStyles({ marginTop });

  const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const getIcon = () => (smallScreen || !action.icon ? null : <action.icon />);

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleRow}>
        <Typography variant="h3" display="inline" color="primary" mb={0}>
          {title}
        </Typography>
        {action && (
          <Tooltip title={action.disabled ? action.disabledTooltipText : ""}>
            <span className={classes.action}>
              <Button
                size={smallScreen ? "medium" : "large"}
                variant="contained"
                startIcon={getIcon()}
                disabled={action.disabled}
                href={action.link}
                onClick={action.onClick}
              >
                {action.text}
              </Button>
            </span>
          </Tooltip>
        )}
      </div>
      {subtitle && (
        <Typography paragraph mb={0} variant="h5" color="textSecondary">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles<Pick<PageTitleProps, "marginTop">>()((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(3),
    position: "relative"
  },
  titleRow: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1)
  },
  action: {
    position: "absolute",
    right: 0
  }
}));

export default PageTitle;
