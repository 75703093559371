import { useEffect } from "react";
import { usePositionsLazyQuery } from "../generated/graphqlHooks";

export const useIndustryPositions = (industryId: string, skip?: boolean) => {
  const [loadPositions, result] = usePositionsLazyQuery();

  useEffect(() => {
    if (!skip && industryId !== undefined && loadPositions)
      loadPositions({
        variables: { industry: industryId }
      });
  }, [industryId, skip, loadPositions]);

  return { ...result, loading: industryId && result.loading };
};
