import { Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { RouteComponentProps } from "@reach/router";
import ObjectID from "bson-objectid";
import React from "react";
import JobApplications from "../components/applications/JobApplications";
import BoxLayout from "../components/BoxLayout";
import JobDetails from "../components/jobs/JobDetails";
import LoadingComponent from "../components/LoadingComponent";
import NotFoundComponent from "../components/NotFoundComponent";
import { APPLICANT_ROLE } from "../constants/general";
import { useApplicantApplicationQuery, useJobWithCompanyQuery } from "../generated/graphqlHooks";
import withAuthentication, { AuthInjectedProps } from "../hocs/withAuthentication";
import PrivateLayout from "../layout/PrivateLayout";

type JobPostProps = RouteComponentProps<{
  jobId: string;
}> &
  AuthInjectedProps;

const Job: React.FC<JobPostProps> = ({ jobId, user }) => {
  const { classes } = useStyles();
  const { data, loading } = useJobWithCompanyQuery({
    variables: { id: jobId }
  });
  const { data: applicationData, loading: applicationLoading } = useApplicantApplicationQuery({
    skip: user.role !== APPLICANT_ROLE || !ObjectID.isValid(jobId),
    variables: {
      jobId
    }
  });

  const isJobPoster = user.uid == data?.job?.recruiterId;

  return (
    <PrivateLayout>
      {loading || applicationLoading ? (
        <LoadingComponent />
      ) : !data.job ? (
        <NotFoundComponent />
      ) : (
        <BoxLayout>
          <JobDetails
            job={data.job}
            company={data.job.company}
            application={applicationData?.applicantApplication}
            withCompanyAvatar
          />
          {isJobPoster && (
            <>
              <Typography variant="h6" className={classes.subtitle} mb={1.5}>
                <strong>Applications</strong>
              </Typography>
              <JobApplications jobId={jobId} />
            </>
          )}
        </BoxLayout>
      )}
    </PrivateLayout>
  );
};

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    marginTop: theme.spacing(2)
  }
}));

export default withAuthentication(Job);
