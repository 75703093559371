import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Button, Paper, SxProps, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React, { useState } from "react";
import { BOX_BACKGROUND_COLOR } from "../constants/styles";

export const BOX_LAYOUT_BORDER_RADIUS = 10;
export const BOX_DEFAULT_ELEVATION = 1;

type StyleProps = {
  /** True to remove padding */
  withoutPadding?: boolean;
  /** True to remove margin */
  withoutMargin?: boolean;
  /** Min height in theme spacing values */
  minHeight?: number;
};

export type BoxLayoutProps = StyleProps & {
  children: React.ReactNode;
  /** ClassName to be applied to Paper element inside the box */
  className?: string;
  expandableArea?: React.ReactNode;
  variant?: "outlined" | "elevation";
  elevation?: number;
  sx?: SxProps;
};

/** A box layout used throughout application to display */
const BoxLayout: React.FC<BoxLayoutProps> = ({
  children,
  withoutPadding,
  withoutMargin,
  minHeight,
  className,
  expandableArea,
  variant = "elevation",
  elevation = BOX_DEFAULT_ELEVATION,
  sx
}) => {
  const { classes, cx } = useStyles({ withoutPadding, withoutMargin, minHeight });
  const [expanded, setExpanded] = useState(false);
  const ExpandIcon = expanded ? ExpandLess : ExpandMore;

  return (
    <Paper className={cx(classes.paper, className)} variant={variant} sx={sx} elevation={elevation}>
      {children}
      {expanded && expandableArea}
      {expandableArea && (
        <div className={classes.moreWrapper}>
          <Button
            className={classes.moreButton}
            color="inherit"
            onClick={() => setExpanded(!expanded)}
            endIcon={<ExpandIcon fontSize="large" color="inherit" />}
          >
            <Typography color="inherit">{expanded ? "Show less" : "Show more"}</Typography>
          </Button>
        </div>
      )}
    </Paper>
  );
};

const padding = 3;
const paddingXs = 2;

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  paper: {
    marginBottom: theme.spacing(props.withoutMargin ? 0 : 2),
    minHeight: props.minHeight ? theme.spacing(props.minHeight) : 0,
    backgroundColor: BOX_BACKGROUND_COLOR,
    borderRadius: BOX_LAYOUT_BORDER_RADIUS,
    padding: props.withoutPadding ? 0 : theme.spacing(padding),
    [theme.breakpoints.down("sm")]: {
      padding: props.withoutPadding ? 0 : theme.spacing(paddingXs)
    }
  },
  moreWrapper: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    margin: props.withoutPadding ? 0 : theme.spacing(padding, -padding, -padding),
    [theme.breakpoints.down("sm")]: {
      margin: props.withoutPadding ? 0 : theme.spacing(paddingXs, -paddingXs, -paddingXs)
    }
  },
  moreButton: {
    width: "100%",
    textTransform: "none",
    padding: 8,
    borderRadius: `0 0 ${BOX_LAYOUT_BORDER_RADIUS}px ${BOX_LAYOUT_BORDER_RADIUS}px`,
    "& .MuiButton-endIcon": {
      marginLeft: 4,
      marginRight: -2
    }
  }
}));

export default BoxLayout;
