import { AccessTimeOutlined, LocationOnOutlined, StorefrontOutlined } from "@mui/icons-material";
import { Avatar, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Link as GLink } from "gatsby";
import { startCase } from "lodash";
import React from "react";
import { employmentTypeToReadable, getCompanyAvatarURL } from "../../constants/general";
import { Application, ApplicationStatus, EmploymentType } from "../../generated/graphqlTypes";
import { formatDistanceToNowWithoutApproximation } from "../../utils/utils";

const applicationStatusToText: Record<string, string> = {
  [ApplicationStatus.UNDER_REVIEW]: "Applied"
};

type JobIconDetailsProps = {
  companyName: string;
  employmentType: EmploymentType;
  avatarURL?: string;
  location?: string;
  applicationInfo?: Pick<Application, "status" | "updatedAt">;
  /** Pass companyId to make company name a link to company page */
  companyId?: string;
};

const JobIconDetails: React.FC<JobIconDetailsProps> = ({
  avatarURL,
  companyName,
  employmentType,
  location,
  applicationInfo,
  companyId
}) => {
  const companyLinkProps = {
    ...(companyId && {
      component: GLink,
      to: `/app/companies/${companyId}`,
      underline: "hover" as const
    })
  };

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        {avatarURL !== null && !avatarURL ? (
          <StorefrontOutlined fontSize="small" sx={{ mr: 1, color: "inherit" }} />
        ) : (
          <Avatar
            src={getCompanyAvatarURL(avatarURL)}
            sx={{ width: "1em", height: "1em", mr: 1 }}
            variant="rounded"
          />
        )}
        <Link underline="none" color="inherit" {...companyLinkProps}>
          {companyName}
        </Link>
      </Box>
      <Box display="flex" alignItems="center" mb={1}>
        <AccessTimeOutlined fontSize="small" sx={{ mr: 1, color: "inherit" }} />
        <Typography>{employmentTypeToReadable(employmentType)}</Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={1}>
        <LocationOnOutlined fontSize="small" sx={{ mr: 1, color: "inherit" }} />
        <Typography>{location}</Typography>
      </Box>
      {applicationInfo?.updatedAt && (
        <Box display="flex" alignItems="center" mt={1}>
          <AccessTimeOutlined fontSize="small" sx={{ mr: 1, color: "inherit" }} />
          <Typography>
            {startCase(
              applicationStatusToText[applicationInfo.status] ||
                applicationInfo.status.toLowerCase()
            )}{" "}
            {formatDistanceToNowWithoutApproximation(new Date(applicationInfo.updatedAt), {
              addSuffix: true
            })}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default JobIconDetails;
