import { Grid } from "@mui/material";
import React from "react";
import { AuthUser } from "../../../contexts/AuthUserContext";
import { Recruiter } from "../../../generated/graphqlTypes";
import QuestionnaireBox from "../../dashboard/QuestionnaireBox";
import CompanyProfile from "../company/CompanyProfile";
import GeneralUserInfo from "../GeneralUserInfo";

export type RecruiterProfileProps = {
  user: AuthUser;
  recruiter: Recruiter;
  questionnaireProgress: number;
};

const RecruiterProfile: React.FC<RecruiterProfileProps> = ({
  user,
  recruiter,
  questionnaireProgress
}) => {
  const isCurrentUser = user.uid === recruiter._id;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={questionnaireProgress !== 100 ? 4 : 12}>
        <GeneralUserInfo info={recruiter} questionnaireResultProgress={questionnaireProgress} />
      </Grid>
      {isCurrentUser && (
        <>
          {questionnaireProgress !== 100 && (
            <Grid item xs={12} sm={8}>
              <QuestionnaireBox progressValue={questionnaireProgress} fullHeight />
            </Grid>
          )}

          <CompanyProfile cid={recruiter.companyId} canModify={isCurrentUser} />
        </>
      )}
    </Grid>
  );
};

export default RecruiterProfile;
