import { Dialog, DialogContent, TextField } from "@mui/material";
import { DialogSaveDeleteActions, DialogTitleWithClose } from "@placehires/react-component-library";
import React, { useState } from "react";
import { useUpdateCompanyMutation } from "../../../generated/graphqlHooks";
import { Company } from "../../../generated/graphqlTypes";
import AvatarSelect from "../AvatarSelect";

type CompanyIntroModalProps = {
  defaultValues: Company;
  open: boolean;
  handleClose: () => void;
};

const CompanyIntroModal: React.FC<CompanyIntroModalProps> = ({
  defaultValues,
  open,
  handleClose
}) => {
  const [name, setName] = useState(defaultValues.name);
  const [avatar, setAvatar] = useState<File>(undefined);
  const [updateCompany, { loading }] = useUpdateCompanyMutation();

  const handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateCompany({
      variables: {
        id: defaultValues._id,
        basicInfo: {
          name
        },
        avatarFile: avatar
      }
    }).then(handleClose);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleUpdate}>
        <DialogTitleWithClose onClose={handleClose}>Edit Company Intro</DialogTitleWithClose>
        <AvatarSelect setAvatar={setAvatar} avatarURL={defaultValues.avatarURL} variant="rounded" />
        <DialogContent>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="firstName"
            variant="outlined"
            required
            fullWidth
            label="Company Name"
          />
        </DialogContent>
        <DialogSaveDeleteActions loadingSave={loading} />
      </form>
    </Dialog>
  );
};

export default CompanyIntroModal;
