import {
  FormikDialog,
  getInitialValues,
  updateCacheAfterAdd,
  updateCacheAfterRemove
} from "@placehires/react-component-library";
import React from "react";
import {
  useAddApplicantEducationMutation,
  useDeleteApplicantEducationMutation,
  useUpdateApplicantEducationMutation
} from "../../../generated/graphqlHooks";
import { ApplicantDocument, Education, EducationDegree } from "../../../generated/graphqlTypes";
import withAuthentication, { AuthInjectedProps } from "../../../hocs/withAuthentication";
import EducationForm from "./EducationForm";

interface EducationModalProps extends AuthInjectedProps {
  open: boolean;
  onClose: () => void;
  onExited: () => void;
  defaultEducation: Education;
}

const EducationModal: React.FC<EducationModalProps> = ({
  open,
  onClose,
  onExited,
  user,
  defaultEducation
}) => {
  const [addEducation] = useAddApplicantEducationMutation();
  const [updateEducation] = useUpdateApplicantEducationMutation();
  const [deleteEducation] = useDeleteApplicantEducationMutation();

  return (
    <FormikDialog
      open={open}
      onExited={onExited}
      onClose={onClose}
      title="Add Education"
      formikConfig={{
        initialValues: getInitialValues(
          {
            institution: "",
            degree: "" as EducationDegree,
            field: "",
            startDate: new Date(),
            endDate: new Date(),
            grade: "",
            description: ""
          },
          defaultEducation
        ),
        onSaveSubmit: async (education) => {
          if (!defaultEducation) {
            await addEducation({
              variables: {
                educationFields: education
              },
              update: (cache, mutationResult) => {
                updateCacheAfterAdd(
                  cache,
                  ApplicantDocument,
                  mutationResult.data.addApplicantEducation,
                  {
                    queryVariables: { id: user.uid },
                    toUpdateListName: "education"
                  }
                );
              }
            });
          } else {
            updateEducation({
              variables: {
                id: defaultEducation._id,
                educationFields: education
              }
            });
          }
        },
        onDeleteSubmit:
          defaultEducation &&
          (() => {
            return deleteEducation({
              variables: {
                id: defaultEducation._id
              },
              update: (cache) => {
                updateCacheAfterRemove(cache, ApplicantDocument, defaultEducation._id, {
                  queryVariables: { id: user.uid },
                  toUpdateListName: "education"
                });
              }
            });
          })
      }}
    >
      <EducationForm />
    </FormikDialog>
  );
};

export default withAuthentication(EducationModal);
