import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import {
  findSelectedOptions,
  ItemOperationsDialog,
  makeStyles
} from "@placehires/react-component-library";
import { ExecutionResult } from "graphql";
import React, { useEffect, useRef, useState } from "react";
import { Skill } from "../../../generated/graphqlTypes";
import ReorderableList from "../../animations/ReorderableList";

type SkillsEditModalProps = {
  defaultSkills: Skill[];
  open: boolean;
  onClose: () => void;
  onSave: (skillsIds: string[]) => Promise<ExecutionResult<any>>;
  loading: boolean;
};

const SkillsEditModal: React.FC<SkillsEditModalProps> = ({
  defaultSkills,
  open,
  onClose,
  onSave,
  loading
}) => {
  const [skills, setSkills] = useState(defaultSkills);
  const reorderedSkillsRef = useRef(defaultSkills.map((skill) => skill._id));
  const { classes } = useStyles();

  useEffect(() => setSkills(defaultSkills), [defaultSkills]);

  // To reset the changes if not saved
  const handleCancel = () => {
    onClose();
    setSkills(defaultSkills);
  };

  const handleUpdate = () => {
    onSave(reorderedSkillsRef.current).then(onClose);
  };

  const handleDelete = (skillId: string) => {
    reorderedSkillsRef.current = reorderedSkillsRef.current.filter((curId) => skillId !== curId);
    setSkills(findSelectedOptions(reorderedSkillsRef.current, skills, "_id"));
  };

  const modalRef = useRef(null);

  const medHeight = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));
  const largeHeight = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const modalHeightSubstr = largeHeight ? 384 : medHeight ? 192 : 64;

  return (
    <ItemOperationsDialog
      ref={modalRef}
      open={open}
      onClose={handleCancel}
      title="Edit Skills"
      onSave={handleUpdate}
      loadingSave={loading}
      noPadding
      maxHeight={`calc(100% - ${modalHeightSubstr}px`}
    >
      <ReorderableList
        constraintRef={modalRef}
        className={classes.skills}
        items={skills}
        getId={(skill) => skill._id}
        renderItem={(skill) => (
          <>
            <Typography variant="h6" className={classes.title}>
              {skill.name}
            </Typography>
            <IconButton onClick={() => handleDelete(skill._id)} size="large">
              <DeleteIcon />
            </IconButton>
          </>
        )}
        itemClassName={classes.skill}
        onOrderChange={(reorderedObjs) => {
          reorderedSkillsRef.current = reorderedObjs.map((item) => item._id);
        }}
      />
    </ItemOperationsDialog>
  );
};

const useStyles = makeStyles()((theme) => ({
  title: {
    flex: 1
  },
  skills: {
    overflowY: "initial"
  },
  skill: {
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 3),
    ":not(:first-child)": {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)"
    }
  }
}));

export default SkillsEditModal;
