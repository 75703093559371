import { GlobalStyles, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { RouteComponentProps } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { LAYOUT_PADDING } from "../constants/styles";

const IMAGE_QUERY = graphql`
  query {
    file(relativePath: { eq: "notFound.png" }) {
      ...fluidImage
    }
  }
`;

const NotFoundComponent: React.FC<RouteComponentProps> = () => {
  const { classes } = useStyles();
  const fluidImage = useStaticQuery(IMAGE_QUERY).file.childImageSharp.gatsbyImageData;

  return (
    <>
      <GlobalStyles styles={`body { overflow-y: hidden; }`} />
      <div className={classes.wrapper}>
        <Typography variant="h1" className={classes.title} mb={1}>
          Page not found
        </Typography>
        <Typography align="center" mb={1}>
          Oops! Something went wrong. <br /> Looks like the link is broken or the page has been
          removed.
        </Typography>
        <div className={classes.img}>
          <GatsbyImage image={fluidImage} loading="eager" alt="Not Found" />
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(LAYOUT_PADDING)
  },
  title: {
    fontWeight: 700
  },
  img: {
    width: 500
  }
}));

export default NotFoundComponent;
