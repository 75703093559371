import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { Link } from "gatsby";
import React from "react";
import { LAYOUT_PADDING } from "../../constants/styles";
import withAuthentication, { AuthInjectedProps } from "../../hocs/withAuthentication";

interface DashboardTopProps extends AuthInjectedProps {
  numNewMatches: number;
}

const DashboardTop: React.FC<DashboardTopProps> = ({ user, numNewMatches }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.topCard}>
      <div className={classes.topCardFlexBox}>
        <Avatar
          component={Link}
          className={classes.avatar}
          src={user.photoURL}
          to={`/app/${user.uid}`}
        />
        <div>
          <Typography variant="h4" color="inherit">
            Welcome {user.displayName},<br />
            {numNewMatches > 0 ? "You have a new match today." : "This is your Dashboard."}
          </Typography>
          <Typography variant="body1">
            I am a third year cook student at Centennial College.
          </Typography>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  topCard: {
    width: "100vw",
    marginLeft: "calc(-50vw + 50%)",
    backgroundColor: "#4A148B1A",
    padding: theme.spacing(LAYOUT_PADDING),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(-LAYOUT_PADDING)
    }
  },
  topCardFlexBox: {
    display: "flex",
    margin: "auto auto",
    alignItems: "center",
    maxWidth: theme.breakpoints.values.md,
    minHeight: 200
  },
  avatar: {
    marginRight: theme.spacing(LAYOUT_PADDING),
    width: theme.spacing(15),
    height: theme.spacing(15)
  }
}));

export default withAuthentication(DashboardTop);
