import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { Radio, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import React, { useEffect, useState } from "react";
import { QuestionOption } from "../../../generated/graphqlTypes";
import BoxLayout from "../../BoxLayout";

const isProd = process.env.GATSBY_ACTIVE_ENV === "production";

export type MatrixAnswer = {
  optionId?: string;
  questionId: string;
  question: {
    _id: string;
    title: string;
    optionGroupId?: string;
    options: QuestionOption[];
    singleVariableDetails?: {
      variableName: string;
    };
  };
};

export type MatrixQuestionProps = {
  answers: MatrixAnswer[];
  onChange: (changedQuestion: string, changedIndex: number, newValue: string) => void;
  onNext?: () => void;
};

const MatrixQuestion: React.FC<MatrixQuestionProps> = ({ answers, onChange, onNext }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    let optionGroupId = null as string;
    if (answers && answers.length) {
      let finished = true;
      answers.forEach(({ question, optionId }) => {
        if (!optionId) finished = false;
        if (!optionGroupId) optionGroupId = question.optionGroupId;
        else if (question.optionGroupId !== optionGroupId) {
          console.error("Matrix questions should have the same option group");
        }
      });
      setCompleted(finished);
    }
  }, [answers]);

  const renderRow = ({ question, optionId }: MatrixAnswer, rowNum: number) => {
    return (
      <tr key={question.title}>
        <th className={classes.titleCell}>
          <Typography align="left">
            {!isProd && question?.singleVariableDetails?.variableName
              ? `[${question?.singleVariableDetails?.variableName}] `
              : ""}
            {question.title}
          </Typography>
        </th>
        {question.options.map((option, index) => (
          <td key={index}>
            <div className={classes.radioCell}>
              <Radio
                color="primary"
                value={option._id}
                checked={option._id === optionId}
                onChange={(event) => {
                  onChange(question._id, rowNum, event.target.value);
                }}
              />
            </div>
          </td>
        ))}
      </tr>
    );
  };

  if (!answers || !answers) return null;

  return (
    <BoxLayout className={classes.box}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th />
            {answers[0].question.options.map((option, index) => (
              <th key={index}>
                <Typography className={classes.header}>{option.text}</Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{answers.map((answers, index) => renderRow(answers, index))}</tbody>
      </table>
      {onNext && (
        <div className={classes.arrowDownWrapper}>
          <KeyboardArrowDownRounded
            fontSize="large"
            sx={{
              cursor: completed ? "pointer" : "not-allowed",
              opacity: completed ? 1 : 0.5,
              background: theme.palette.primary.main,
              borderRadius: "50%",
              color: "white",
              pt: "3px"
            }}
            onClick={() => {
              if (completed) {
                onNext();
              }
            }}
          />
        </div>
      )}
    </BoxLayout>
  );
};

const useStyles = makeStyles()((theme) => ({
  box: {
    overflowX: "auto",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  table: {
    width: "100%"
  },
  header: {
    fontWeight: 500
  },
  titleCell: {
    minWidth: 300,
    padding: theme.spacing(1, 0)
  },
  radioCell: {
    display: "flex",
    justifyContent: "center"
  },
  arrowDownWrapper: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1, 0, -1)
  }
}));

export default MatrixQuestion;
