import { useState } from "react";

export const useModalState = (initialOpen = false) => {
  const [open, setOpen] = useState(initialOpen);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return [open, handleOpen, handleClose] as const;
};
