import { Avatar, Divider, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@placehires/react-component-library";
import { Link as GLink } from "gatsby";
import React from "react";
import { employmentTypeToReadable, getCompanyAvatarURL } from "../../constants/general";
import { PREVIEW_AVATAR_DIMENSIONS } from "../../constants/styles";
import { Job, JobStatus } from "../../generated/graphqlTypes";

const jobStatusToRecruiterText: Record<JobStatus, string> = {
  [JobStatus.OPEN]: "Under review",
  [JobStatus.DISAPPROVED]: "Rejected",
  [JobStatus.APPROVED]: "Open",
  [JobStatus.MATCHED]: "Open",
  [JobStatus.PAUSED]: "Open",
  [JobStatus.FILTERED]: "Matched",
  [JobStatus.HIRED]: "Hired"
};

type RecruiterJobPreviewProps = {
  job: Job;
  withDivider?: boolean;
};

const RecruiterJobPreview: React.FC<RecruiterJobPreviewProps> = ({
  job: { _id, position, location, company, employmentType, status },
  withDivider
}) => {
  const { classes } = useStyles();

  return (
    <>
      <Link className={classes.wrapper} to={_id} component={GLink} underline="none" color="inherit">
        <Avatar
          src={getCompanyAvatarURL(company.avatarURL)}
          className={classes.avatar}
          variant="rounded"
        />
        <div className={classes.mainInfo}>
          <Typography variant="h6">{position.name}</Typography>
          <Typography color="textSecondary">{location}</Typography>
          <Typography color="textSecondary">{employmentTypeToReadable(employmentType)}</Typography>
          {status && (
            <Typography color="textSecondary" className={classes.status} variant="subtitle2">
              Status: {jobStatusToRecruiterText[status] || "Open"}
            </Typography>
          )}
        </div>
      </Link>
      {withDivider && <Divider variant="fullWidth" className={classes.divider} />}
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "flex",
    cursor: "pointer"
  },
  avatar: {
    ...PREVIEW_AVATAR_DIMENSIONS
  },
  mainInfo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2)
  },
  status: {},
  divider: {
    margin: theme.spacing(3, 0)
  }
}));

export default RecruiterJobPreview;
