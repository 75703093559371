import {
  AssignmentTurnedInOutlined,
  Block,
  ChatBubbleOutline,
  ExitToApp,
  ExpandMore,
  Help
} from "@mui/icons-material";
import { Avatar, Card, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import {
  alertSuccess,
  ExtendedButton,
  makeStyles,
  updateCacheAfterRemove
} from "@placehires/react-component-library";
import { Link } from "gatsby";
import React from "react";
import { APPLICANT_ROLE, RECRUITER_ROLE } from "../../constants/general";
import { LAYOUT_PADDING } from "../../constants/styles";
import {
  useConfirmInterestInJobMutation,
  useRemoveApplicationMutation,
  useUpdateApplicationStatusMutation
} from "../../generated/graphqlHooks";
import {
  ApplicantViewableApplicationsDocument,
  Application,
  ApplicationStatus,
  RecruiterApplicationsByStatusDocument
} from "../../generated/graphqlTypes";

type MatchCardProps = {
  application: Application;
  userRole: string;
  callback?: () => void;
};

const MatchCard: React.FC<MatchCardProps> = ({
  application: {
    _id,
    status,
    job: { _id: jobId, positionId, location, company },
    applicant
  },
  userRole,
  callback
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [applyToJob, { loading: applyLoading }] = useConfirmInterestInJobMutation();
  const [removeApplication] = useRemoveApplicationMutation();
  const [updateApplicationStatus, { loading: updateLoading }] =
    useUpdateApplicationStatusMutation();
  const avatarSRC = userRole === APPLICANT_ROLE ? company.avatarURL : applicant.avatarURL;
  const name =
    userRole === APPLICANT_ROLE
      ? company.name
      : applicant.firstName.concat(` ${applicant.lastName}`);
  const loading = updateLoading || applyLoading;
  const { classes } = useStyles();

  const handleApply = async () => {
    await applyToJob({ variables: { id: _id } });
    alertSuccess("You applied to the job!");
  };

  const handleCancel = async () => {
    if (userRole === APPLICANT_ROLE) {
      await removeApplication({
        variables: { id: _id },
        update: (cache) => {
          updateCacheAfterRemove(cache, ApplicantViewableApplicationsDocument, _id);
        }
      });
      alertSuccess("Your match was cancelled.");
    } else {
      await updateApplicationStatus({
        variables: {
          id: _id,
          status: ApplicationStatus.NO_LONGER_UNDER_CONSIDERATION
        },
        update: (cache) => {
          updateCacheAfterRemove(cache, RecruiterApplicationsByStatusDocument, _id, {
            queryVariables: {
              status: ApplicationStatus.UNDER_REVIEW
            }
          });
        }
      });
      alertSuccess("Applicant no longer considered.");
    }
  };

  const handleSelectInterview = async () => {
    await updateApplicationStatus({
      variables: {
        id: _id,
        status: ApplicationStatus.SELECTED_FOR_INTERVIEW
      },
      update: (cache) => {
        updateCacheAfterRemove(cache, RecruiterApplicationsByStatusDocument, _id, {
          queryVariables: {
            status: ApplicationStatus.UNDER_REVIEW
          }
        });
      }
    });
    alertSuccess("Applicant selected for interview!");
    callback();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card className={classes.matchCard}>
      <div className={classes.flexCenter}>
        <Help />
        <Avatar src={avatarSRC} />
      </div>
      <div>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="subtitle2">{location}</Typography>
      </div>
      <div>
        <Typography variant="h6">{positionId}</Typography>
        <Typography variant="subtitle2">Position</Typography>
      </div>
      <div>
        <Typography variant="h6">Internship</Typography>
        <Typography variant="subtitle2">Experience Type</Typography>
      </div>
      <ExtendedButton loading={loading} variant="contained" color="primary" onClick={handleClick}>
        Action <ExpandMore />
      </ExtendedButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {userRole === APPLICANT_ROLE && (
          <MenuItem onClick={handleApply} disabled={status != ApplicationStatus.CREATED}>
            <ListItemIcon>
              <AssignmentTurnedInOutlined color="primary" />
            </ListItemIcon>
            Apply
          </MenuItem>
        )}
        {userRole === RECRUITER_ROLE && (
          <MenuItem
            onClick={handleSelectInterview}
            disabled={status != ApplicationStatus.UNDER_REVIEW}
          >
            <ListItemIcon>
              <AssignmentTurnedInOutlined color="primary" />
            </ListItemIcon>
            Select for Interview
          </MenuItem>
        )}
        <MenuItem onClick={handleCancel}>
          <ListItemIcon>
            <Block color="primary" />
          </ListItemIcon>
          Cancel Match
        </MenuItem>
        <MenuItem component={Link} to={`/app/jobs/${jobId}`}>
          <ListItemIcon>
            <ExitToApp color="primary" />
          </ListItemIcon>
          Go to Job Posting
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <ChatBubbleOutline color="primary" />
          </ListItemIcon>
          Message
        </MenuItem>
      </Menu>
    </Card>
  );
};

const useStyles = makeStyles()((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center"
  },
  matchCard: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(LAYOUT_PADDING),
    alignItems: "center",
    marginTop: theme.spacing(LAYOUT_PADDING)
  }
}));

export default MatchCard;
